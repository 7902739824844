import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { } from "@oceantech/oceantech-ui";
import axios from "axios";
import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { AppContext } from "../../appContext/AppContext";
import AutocompleteObjectV2 from '../../component/AutocompleteObjectV2';
import LabelRequired from "../../component/LabelRequired";
import TextField from "../../component/TextField";
import { formatStringDateDTO } from '../../utils/FormatUtils';
import OCTTextValidator from "../text-validator";
import "./confirmDialog.scss";
import './generateForm.scss';
import {
  COMPONENT_TYPE,
  handleThrowResponseMessage,
  IObject,
  isSuccessfulResponse,
  replaceUrl
} from "./GenerateFormConfig";
import { localStorageItem } from './../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import DatePickerCustom from "../date-picker/DatePickerCustom";
import { generateDataByUrl, generateSearchParams, useDebounce } from "../../utils/AppFunction";
import { validateData } from '../../utils/JsonLogic';
interface IDialogProps {
  warehouseId?: any;
  listFieldAuto?: any;
  onCloseClick?: () => void;
  handleSave?: (data: any) => void;
  onCancelClick?: () => void;
  title?: string;
  message?: string;
  isView?: boolean;
  isUpdate?: boolean;
  itemEdit?: IObject;
  validation?: IObject;
  isSave?: boolean;
  modelID?: any;
  handleSubmit?: any;
  setValues?: any;
  setTouched?: any;
  setFieldValue?: any;
  values?: any;
  errors?: any;
  touched?: any;
  isValid?: any;
  handleChange?: any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  propsRenderButton?: any;
  handleFilterDataRenderButton?: ((data: any, dataSelected: any) => void) | null;
  lstSelectedRenderButton?: any[];
  DynamicClass?: any;
  isNhapKho?: boolean;
  isIventoryNoZero?: boolean;
  isViewSelectThuoc?: boolean;
  customComponent?: (props: { data: any }) => React.ReactNode;
  customFieldReadOnly?: {
    [key: string]: boolean
  };
  customSearchObject?: {
    [key: string]: {
      [key: string]: any
    },
  };
  onFieldPropsChange?: (data: ItemChangeProps) => void;
}
interface ItemTypeProps {
  autofocus?: boolean;
  clearOnHide?: boolean;
  conditional?: IObject;
  defaultValue?: string;
  hidden?: boolean;
  input?: boolean;
  inputType?: string;
  inputFormat?: string;
  inputMask?: string;
  key: string;
  label?: string;
  lablelPosition?: string;
  multiple?: boolean;
  placeholder?: string;
  persistent?: boolean;
  prefix?: string;
  protected?: boolean;
  properties?: IObject;
  spellcheck?: boolean;
  suffix?: string;
  unique?: boolean;
  width?: number;
  offset?: number;
  push?: number;
  pull?: number;
  type?: string;
  value?: string;
  tag?: keyof JSX.IntrinsicElements;
  tags?: string[];
  validate?: IObject;
  columns?: IObject;
  values?: IObject;
  components?: ItemTypeProps[];
  data?: IObject;
  filePattern?: string;
  fileMaxSize?: string;
  fileMinSize?: string;
  format?: string;
  url?: string;
  fields?: any;
  content?: string;
  hideLabel?: boolean;
  inline?: boolean;
  className?: string;
  overlay?: any;
  searchObject?: any;
  rows: number;
  customClass?: string;
  disabled: boolean,
  searchData?: any,
}
export interface ItemChangeProps {
  currentInput?: string,
  value?: any,
}
type RequestQueue = {
  [key: string]: {
    promise: Promise<any>;
    resolve: (value: any) => void;
    reject: (reason?: any) => void;
  };
};
const GenerateFormComponent: FC<IDialogProps> = (props) => {
  const {
    warehouseId,
    isView,
    modelID,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
    handleChange = () => { },
    onKeyDown,
    listFieldAuto,
    itemEdit = null,
    customComponent,
    DynamicClass,
    propsRenderButton,
    handleFilterDataRenderButton,
    lstSelectedRenderButton,
    isNhapKho = false,
    isIventoryNoZero = false,
    isViewSelectThuoc = false,
    customFieldReadOnly,
    customSearchObject,
    onFieldPropsChange,
  } = props;
console.log(isNhapKho)
  const { lang } = useMultiLanguage();
  const { setIsLoading } = useContext(AppContext);

  useEffect(() => {
    Object.keys(itemEdit || {})?.length && setValues({
      ...values,
      [modelID]: itemEdit
    })
  }, [itemEdit]);

  const handleFieldChangeValidateDiasbled = (data: ItemChangeProps) => {
    onFieldPropsChange && onFieldPropsChange(data);
  }
  const handleChangeFile = async (e: any, item: ItemTypeProps) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("uploadedFile", file);

    if (item.url) {
      try {
        setIsLoading(true);
        let res = await uploadFile(item?.url, formData);
        const data = res.data;
        if (isSuccessfulResponse(res.status)) {
          setIsLoading(false);
          let attachment = data.file || data?.data;
          let attachments = [];
          let fileDescriptionIds = [];

          attachments.push(attachment);
          fileDescriptionIds.push(attachment?.id);
          await setFieldValue(e.target.name, fileDescriptionIds);
          let objFieldChange: ItemChangeProps = {
            value: fileDescriptionIds,
            currentInput: e.target.name
          }
          handleFieldChangeValidateDiasbled(objFieldChange)
          toast.success(lang("GENERAL.SUCCESS"));
        } else {
          handleThrowResponseMessage(res);
        }
      } catch {
        toast.error(lang("GENERAL.ERROR"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const uploadFile = (url: string | undefined | null, formData: any) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return axios.post(url || "", formData, config);
  };

  const handleChangeCheck = (name: string, value: any, modelID: string, e: any) => {
    const valueChange = value ? "1" : "0";
    setFieldValue(modelID, {
      ...values?.[modelID],
      [name]: valueChange,
    });

    let objFieldChange: ItemChangeProps = {
      value: valueChange,
      currentInput: name
    }
    handleFieldChangeValidateDiasbled(objFieldChange)
  };

  const handleChangeValueText = (name: string, value: any, modelID: string, dataComponent?: any) => {
    let valueConvert = value
    if (dataComponent?.inputType === "number") {
      valueConvert = Number(value)
    }
    if (dataComponent?.type === "datetime") {
      valueConvert = formatStringDateDTO(value)
    }
    setFieldValue(modelID, {
      ...values?.[modelID],
      [name]: valueConvert,
    });

    let objFieldChange: ItemChangeProps = {
      value: value,
      currentInput: name
    }
    handleFieldChangeValidateDiasbled(objFieldChange)
  };

  const getValueFromTemplate = (keyStr: string, props: any, defaultValue: any = '') => {
    return keyStr.split('.').reduce((acc, currKey) => acc?.[currKey], props) ?? defaultValue;
  };
  
  const replaceTemplateString = (template: string, data: any) => {
    return template.replace(/\${(.*?)}/g, (_: any, key: any) => {
      const value = getValueFromTemplate(key, data);
      return value !== undefined  ? JSON.stringify(value) : '';
    });
  };
  
  const replaceTemplateValues = (template: any, data: any): any => {
    if (typeof template === 'string') {
      return template.includes('${')
        ? JSON.parse(replaceTemplateString(template, data))
        : template;
    }
    if (Array.isArray(template)) {
      return template.map(item => replaceTemplateValues(item, data));
    }
    if (typeof template === 'object' && template !== null) {
      return Object.fromEntries(
        Object.entries(template).map(([key, value]) => [
          key,
          replaceTemplateValues(value, data),
        ])
      );
    }
    return template;
  };

  const handleChangeValueSelect = (e: any, modelID: string, dataComponent?: any) => {
    const customField = replaceTemplateValues(dataComponent.properties, e);
    const key = dataComponent?.key || '';

    setFieldValue(modelID, {
      ...values?.[modelID],
      [key]: e?.code || e?.id,
      [`${key}Id`]: e?.id,
      [`${key}Name`]: e?.name,
      [`${key}Code`]: e?.code,
      ...customField,
    });

    let objFieldChange: ItemChangeProps = {
      value: e,
      currentInput: dataComponent?.key
    };
    handleFieldChangeValidateDiasbled(objFieldChange)
  };

  const requestQueue: RequestQueue = {};

  const addToQueue = (urlData: string, func: () => Promise<any>): Promise<any> => {
    if (requestQueue[urlData]) {
      return requestQueue[urlData].promise;
    }

    let resolve: (value: any) => void;
    let reject: (reason?: any) => void;
    const promise = new Promise<any>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    requestQueue[urlData] = { promise, resolve: resolve!, reject: reject! };

    func()
      .then(result => {
        requestQueue[urlData].resolve(result);
        delete requestQueue[urlData];
      })
      .catch(error => {
        requestQueue[urlData].reject(error);
        delete requestQueue[urlData];
      });

    return promise;
  };

  const handleGetDataByUrlData = async (urlData: string, searchObject?: any, dataComponent?: any) => {
    if (!urlData) return;
    const API_ENDPOINT =
      localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.[dataComponent?.selectValues]
      || localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"]
      || process.env.REACT_APP_WMS_API_URL;

    let url = `${API_ENDPOINT}/${urlData}`;

    // Xác định funcApi dựa trên urlData
    const funcApi = urlData === 'item/search'
      ? () => axios.post(url, {})
      : () => axios.get(url, { params: searchObject });

    // Nếu có searchObject, gọi funcApi trực tiếp
    if (searchObject || dataComponent?.clearOnRefresh) {
      return funcApi();
    }

    // Nếu không có searchObject, gọi thông qua addToQueue
    return addToQueue(urlData, () => generateDataByUrl(urlData, funcApi));
  };

  const handleChangeSelectBoxes = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    source: string
  ) => {
    let newArray = values?.[source] || [];
    newArray[index].value = e.target.checked;
    setFieldValue(source, newArray);

    let objFieldChange: ItemChangeProps = {
      value: e.target.checked,
      currentInput: source
    }
    handleFieldChangeValidateDiasbled(objFieldChange)
  };

  const debouncedValues = useDebounce(values, 500);
  const [searchData, setSearchData] = useState(generateSearchParams(listFieldAuto));

  useEffect(() => {
    const newSearchData = { ...searchData };

    Object.keys(newSearchData).forEach((key) => {
      Object.keys(newSearchData[key]).forEach((keySearch) => {
        const newValue = values[modelID]?.[keySearch];
        if (newValue !== newSearchData[key][keySearch] && newValue !== undefined) {
          newSearchData[key][keySearch] = newValue;
        }
      });
    });

    setSearchData(newSearchData);
  }, [debouncedValues]);


  const renderHTML = (dataForm: any, configKey?: string) => {
    if (dataForm?.components || dataForm?.[configKey || "components"]) {
      let newArray = dataForm?.components || dataForm?.[configKey || ""] || [];

      if (Object.prototype.toString.call(newArray) === "[object Object]") {
        newArray = Object.entries(newArray).map(([key, value]) => {
          return value;
        });
      }

      let filteredComponents = newArray?.filter(
        (item: ItemTypeProps) => item.type !== COMPONENT_TYPE.BUTTON
      );
      return filteredComponents?.map((dataComponent: ItemTypeProps) => {
        const isDisabled = (customFieldReadOnly && customFieldReadOnly?.[dataComponent.key] !== undefined)
          ? customFieldReadOnly?.[dataComponent.key]
          : (isView || dataComponent.disabled);

        switch (dataComponent?.type) {
          case COMPONENT_TYPE.EMAIL:
          case COMPONENT_TYPE.PASSWORD:
          case COMPONENT_TYPE.TEXTFIELD:
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces pt-8 ${dataComponent?.customClass}`}
                >
                  <TextField
                    hideLabel={dataComponent?.hideLabel}
                    label={dataComponent?.label}
                    name={dataComponent?.key}
                    type={dataComponent.inputType}
                    onChange={(e: any) => {
                      handleChangeValueText(e.target.name, e.target.value, modelID)
                    }}
                    onKeyDown={onKeyDown}
                    value={values?.[modelID]?.[dataComponent?.key] || ""}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    placeholder={dataComponent.placeholder}
                    disabled={isDisabled}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.COLUMNS:
            return (
              <Row className={`generate-form-${dataComponent.customClass}`}>
                {dataComponent?.columns &&
                  dataComponent?.columns?.length > 0 &&
                  dataComponent?.columns?.map((dataColumn: ItemTypeProps, index: number) => {
                    return (
                      <Col className={`${modelID}-${dataComponent.key}-${index}`} lg={dataColumn?.width}>{renderHTML(dataColumn)}</Col>
                    );
                  })}
              </Row>
            );
          case COMPONENT_TYPE.FILE:
            return (
              <>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="text-label-input label m-0">
                    {dataComponent?.label}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name={dataComponent.key}
                    max={dataComponent?.fileMaxSize}
                    min={dataComponent?.fileMinSize}
                    accept={dataComponent?.filePattern}
                    onChange={(e) => handleChangeFile(e, dataComponent)}
                    value={values?.[dataComponent?.key]}
                    disabled={isDisabled}
                  />
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.DAY:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Row>
                  <Col
                    lg={3}
                    className="spaces pr-0 mb-3 pt-8"
                  >
                    <OCTTextValidator
                      hideLabel={dataComponent?.hideLabel}
                      label={""}
                      name={dataComponent.placeholder}
                      type={"text"}
                      onChange={handleChange}
                      isRequired={dataComponent?.validate?.required}
                      value={values?.[dataComponent?.key]}
                      touched={touched?.[dataComponent?.key]}
                      errors={errors?.[dataComponent?.key]}
                      placeholder={""}
                      disabled={isDisabled}
                    />
                  </Col>
                  <Col
                    lg={3}
                    className="spaces mb-3 pl-0 pr-0 pt-8"
                  >
                    <OCTTextValidator
                      hideLabel={dataComponent?.hideLabel}
                      label={""}
                      name={dataComponent.placeholder}
                      type={"text"}
                      onChange={handleChange}
                      isRequired={dataComponent?.validate?.required}
                      value={values?.[dataComponent?.key]}
                      touched={touched?.[dataComponent?.key]}
                      errors={errors?.[dataComponent?.key]}
                      placeholder={""}
                      disabled={isDisabled}
                    />
                  </Col>
                  <Col
                    lg={3}
                    className="spaces mb-3 pl-0 pt-8"
                  >
                    <OCTTextValidator
                      hideLabel={dataComponent?.hideLabel}
                      label={""}
                      name={dataComponent.placeholder}
                      type={"text"}
                      onChange={handleChange}
                      isRequired={dataComponent?.validate?.required}
                      value={values?.[dataComponent?.key]}
                      touched={touched?.[dataComponent?.key]}
                      errors={errors?.[dataComponent?.key]}
                      placeholder={""}
                      disabled={isDisabled}
                    />
                  </Col>
                  {dataComponent?.key == "ngaySinh" &&
                    <>
                      <Col
                        lg={1}
                        className="spaces mb-3 pl-0 pt-8"
                      >
                        <div className="spaces d-flex flex-center fw-bold">
                          -
                        </div>
                      </Col>

                      <Col
                        lg={2}
                        className="spaces mb-3 pl-0 pt-8"
                      >
                        <OCTTextValidator
                          hideLabel={dataComponent?.hideLabel}
                          label={""}
                          name={dataComponent?.key}
                          type={"text"}
                          onChange={handleChange}
                          isRequired={dataComponent?.validate?.required}
                          value={values?.[dataComponent?.key]}
                          touched={touched?.[dataComponent?.key]}
                          errors={errors?.[dataComponent?.key]}
                          placeholder={""}
                          disabled={isDisabled}
                        />
                      </Col>
                    </>
                  }
                </Row>
              </>
            );
          case COMPONENT_TYPE.TIME:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Control
                  type="time"
                  placeholder="Time"
                  onChange={handleChange}
                  value={values?.[dataComponent?.key]}
                  disabled={isDisabled}
                />

              </>
            );
          case COMPONENT_TYPE.DATETIME:
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces pt-8 ${dataComponent?.customClass}`}
                >
                  {/* <TextField
                    hideLabel={dataComponent?.hideLabel}
                    label={dataComponent?.label}
                    name={dataComponent?.key}
                    type={"date"}
                    onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID, dataComponent)}
                    value={formatStringDateToYYYYMMDD(values?.[modelID]?.[dataComponent?.key]) || ""}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    placeholder={dataComponent.placeholder}
                    disabled={isView || dataComponent.disabled}
                  /> */}
                  <DatePickerCustom
                    name={dataComponent?.key}
                    // value={searchObj?.doCreationToDate || ""}
                    value={values?.[modelID]?.[dataComponent?.key] || ""}
                    setDateValue={date => {
                      // handleChangeSearchData("doCreationToDate", date);
                      handleChangeValueText(dataComponent?.key, date, modelID, dataComponent)
                    }}
                    dateFormatOutput="YYYY-MM-DD"
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    placeholder={dataComponent.placeholder}
                    disabled={isDisabled}
                  />
                </Col>

              </>
            );
          case COMPONENT_TYPE.NUMBER:
          case COMPONENT_TYPE.PHONE:
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces pt-8 ${dataComponent?.customClass}`}
                >
                  <TextField
                    hideLabel={dataComponent?.hideLabel}
                    label={dataComponent?.label}
                    name={dataComponent?.key}
                    type={"number"}
                    onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID, dataComponent)}
                    onKeyDown={onKeyDown}
                    value={values?.[modelID]?.[dataComponent?.key] ?? ""}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    placeholder={dataComponent.placeholder}
                    disabled={isDisabled}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.TEXTAREA:

            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces ${dataComponent?.customClass}`}
                >
                  <TextField
                    name={dataComponent?.key}
                    className={`spaces py-4 min-h-${30 * dataComponent.rows + (5 * (dataComponent.rows - 1))}px`}
                    labelClassName="ps-2"
                    onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID)}
                    as="textarea"
                    disabled={isDisabled}
                    value={values?.[modelID]?.[dataComponent?.key] || ""}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    label={!dataComponent?.hideLabel && dataComponent?.label}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.CHECKBOX:
            return (
              <>
                <Form.Group className="spaces pt-10 form-check-label-black" controlId={dataComponent?.key}>
                  <FormCheck
                    name={dataComponent.key}
                    type="checkbox"
                    label={dataComponent.hideLabel ? "" : dataComponent?.label}
                    className="d-flex align-items-center spaces gap-7"
                    checked={values?.[modelID]?.[dataComponent?.key] === "1"}
                    onChange={(e: any) => handleChangeCheck(e.target.name, e.target.checked, modelID, e)}
                    disabled={isDisabled}
                  />
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.SELECTBOXES:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Group controlId={dataComponent?.key} className={`${dataComponent?.inline && 'd-flex gap-10'}`}>
                  {dataComponent?.values?.map(
                    (dataItem: any, index: number) => (
                      <Form.Check
                        type="checkbox"
                        label={dataItem?.label}
                        checked={Boolean(
                          values[dataComponent?.key]?.[index]?.value
                        )}
                        onChange={(e) =>
                          handleChangeSelectBoxes(e, index, dataComponent?.key)
                        }
                        disabled={isDisabled}
                      />
                    )
                  )}
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.RADIO:
            return (
              <>
                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                <Form.Group className="d-flex spaces pt-8" controlId="formBasicCheckbox">
                  {dataComponent?.values?.map((dataItem: any) => (
                    <Form.Check
                      inline
                      type="radio"
                      label={dataItem?.label}
                      value={dataItem.value}
                      name={dataComponent?.key}
                      checked={
                        values?.[dataComponent?.key] === dataItem.value
                      }
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                  ))}
                </Form.Group>
              </>
            );
          case COMPONENT_TYPE.SELECT:
            const options = dataComponent.data?.values;
            let newOptions = [];
            const hasEmptyValue = options.some(
              (item: { value: string; label: string }) =>
                item.label === "" || item.value === ""
            );
            if (!hasEmptyValue) {
              newOptions = options.map(
                (item: { value: string; label: string }) => ({
                  name: item.label,
                  code: item.value,
                })
              );
            }
            return (
              <>
                <Col
                  lg={dataComponent?.width ? dataComponent?.width : 12}
                  className={`spaces ${dataComponent?.customClass}`}
                >
                  <AutocompleteObjectV2
                    options={newOptions}
                    isSearchDefauilt={true}
                    name={dataComponent?.key}
                    onChange={(e: any) => handleChangeValueSelect(e, modelID, dataComponent)}
                    searchFunction={
                      hasEmptyValue
                        ? () =>
                          handleGetDataByUrlData(
                            replaceUrl(dataComponent?.data?.url || ""),
                            {
                              ...customSearchObject?.[dataComponent?.key],
                              ...searchData?.[dataComponent?.key],
                            },
                            dataComponent
                          )
                        : null
                    }
                    value={values?.[modelID]?.[dataComponent?.key] || values?.[modelID]?.[`${dataComponent?.key}Code`] || ''}
                    touched={touched?.[modelID]?.[dataComponent?.key]}
                    errors={errors?.[modelID]?.[dataComponent?.key]}
                    placeholder={dataComponent?.placeholder || ""}
                    searchObject={{
                      ...customSearchObject?.[dataComponent?.key],
                      ...searchData?.[dataComponent?.key],
                    }}
                    className="autocomplete-custom-renderform mt-8 radius spaces width-100 h-29"
                    disabled={isDisabled}
                  />
                </Col>
              </>
            );
          case COMPONENT_TYPE.BUTTON:
            return (
              <>
                <Button>
                  {lang(`BTN.${dataComponent?.label?.toUpperCase()}`)}
                </Button>
              </>
            );
          case COMPONENT_TYPE.CONTENT:
            return (
              <>
                <div className="spaces pt-10 ">
                  <CKEditor
                    editor={ClassicEditor}
                    disabled={isDisabled}
                    onChange={(event: any, editor: any) => {
                      setFieldValue(dataComponent?.key, editor.getData());
                    }}
                    data={values?.[dataComponent?.key] || ""}
                  />
                </div>
              </>
            );
          case COMPONENT_TYPE.HTML:
            return (
              <div className="spaces pt-10 min-w-135">
                {dataComponent?.tag == "span" ?
                  <div className="">
                    <LabelRequired className={`min-w-100px ${dataComponent?.className}`} label={dataComponent?.content || ""} />
                  </div> :
                  (
                    dataComponent?.tag == "p" ?
                      <div className={`spaces  mt-1  ${dataComponent?.className}`}>
                        <LabelRequired className="min-w-100px" isRequired={dataComponent?.className?.includes('isRequired')} label={dataComponent?.content || ""} />
                      </div>
                      : <div className="hyperlink">{dataComponent?.label}</div>
                  )
                }
              </div>
            );
          case COMPONENT_TYPE.CUSTOM:
            let searchDataCustom: any = null;
            if (dataComponent.searchData) {
              searchDataCustom = replaceTemplateValues(dataComponent.searchData, values[modelID]);
            };
            
            const shouldDisplay = dataComponent.conditional?.json ? validateData(dataComponent.conditional?.json, values[modelID]).isValid : true;
            
            return customComponent ? customComponent({
              data: {
                warehouseId: warehouseId,
                componentName: dataComponent.key,
                setThuocSelected: propsRenderButton,
                values: values?.[modelID] || "",
                DynamicClass: DynamicClass,
                isNhapKho: isNhapKho,
                isIventoryNoZero: isIventoryNoZero,
                isViewSelectThuoc: isViewSelectThuoc || isView || !shouldDisplay,
                handleFilterDataTable: handleFilterDataRenderButton,
                lstDataSelected: lstSelectedRenderButton,
                searchData: searchDataCustom,
              }
            }) : <></>;
          default:
            return <></>;
        }
      });
    } else {
      return <></>;
    }
  };


  return (
    <Form >
      {renderHTML(listFieldAuto)}
    </Form>
  );
};

export default GenerateFormComponent;
