
import { OctTable } from "@oceantech/oceantech-ui";
import { Dispatch, SetStateAction, useContext } from "react";
import {formatDateToDDMMYYYY, formatMoney} from "../../utils/FormatUtils";
import { PhanHeNhapKhoContext } from "../PhanHeNhapKho";
import '../PhanHeNhapKho.scss';
import { CODE_NHAP_KHO, WMS_STATUS } from "../constants/constansNhapKho";
import { TypeNhapKho } from "../models/NhapKhoModel";
import {WAREHOUSE_ID} from "../../utils/Constant";
interface Props {
  isView?: boolean;
  indexRowEdit: number | null;
  setIndexRowEdit: Dispatch<SetStateAction<number | null>>,
  setInitialValuesFormThemVT: Dispatch<SetStateAction<any>>,
  type: TypeNhapKho,
  warehouseId?: any,
}

const TableThongTinNhapThuoc = (props: Props) => {
  const { isView, indexRowEdit, setIndexRowEdit, setInitialValuesFormThemVT, type, warehouseId } = props;
  const { dsNhapKho, setDsNhapKho, detailPhieuNhap } = useContext(PhanHeNhapKhoContext);

  const hanldeDeleteRow = (index: number) => {
    const data = [...dsNhapKho].filter((_: any, i: number) => i !== index);
    setDsNhapKho(data);
  };
  const handleEdit = (rowData: any, index: number) => {
    setInitialValuesFormThemVT({
      ...rowData?.item,
      ...rowData,
      id: rowData?.id || null,
      countryofOriginName: rowData?.countryofOriginName || rowData?.item?.countryofOriginName,
      manufacturerName: rowData?.manufacturerName || rowData?.item?.manufacturerName,
      code: rowData.code || rowData.item?.code,
      isFirstClick: true,
    });
    setIndexRowEdit(index);
  }

  const columnsNCC = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    ...(!isView ?
      [
        {
          name: "TT",
          field: "",
          headerStyle: {
            minWidth: 80,
            maxWidth: 80,
          },
          cellStyle: {
            textAlign: "center",
          },
          render: (row: any, index: number, stt: number) =>
            <div className="d-flex justify-content-center">
              {indexRowEdit !== index && <>
                <i
                  onClick={(e) => handleEdit(row, index)}
                  className="bi bi-pencil-square text-navy"
                ></i>
                <i
                  onClick={(e) => hanldeDeleteRow(index)}
                  className="bi bi-trash3-fill text-danger spaces ml-10  "
                ></i>
              </>}
            </div>
        },
      ] : []),

    {
      name: "Mã vật tư",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.code || row.item?.code || '',
    },
    {
      name: "Tên vật tư",
      field: "name",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.name || row.item?.name || '',

    },
    {
      name: "ĐVT",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.unitofMeasureName || row.item?.unitofMeasureName || '',
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalQuantity) || '',
    },
    {
      name: "Giá mua",
      field: "sellingPrice",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.sellingPrice) || '',
    },
    {
      name: "VAT",
      field: "vat",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.vat || '',
    },
    {
      name: "Giá mua thực",
      field: "",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => {
        let price = parseFloat(row.sellingPrice || 0);
        let vat = parseFloat(row.vat || 0);
        return formatMoney(price * (1 + vat / 100));
      },
    },
    {
      name: "Thành tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalAmount) || '',

    },
  ];

  const columnsNCC_KHOMAU = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    ...(!isView ?
      [
        {
          name: "TT",
          field: "",
          headerStyle: {
            minWidth: 80,
            maxWidth: 80,
          },
          cellStyle: {
            textAlign: "center",
          },
          render: (row: any, index: number, stt: number) =>
            <div className="d-flex justify-content-center">
              {indexRowEdit !== index && <>
                <i
                  onClick={(e) => handleEdit(row, index)}
                  className="bi bi-pencil-square text-navy"
                ></i>
                <i
                  onClick={(e) => hanldeDeleteRow(index)}
                  className="bi bi-trash3-fill text-danger spaces ml-10  "
                ></i>
              </>}
            </div>
        },
      ] : []),
    {
      name: "Mã Máu",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.code || row.item?.code || '',
    },
    {
      name: "Tên Máu/CP",
      field: "name",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.name || row.item?.name || '',
    },
    {
      name: "Nhóm Máu",
      field: "type",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.name || row.item?.name || '',
    },
    {
      name: "Số Lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalQuantity) || '',
    },
    {
      name: "Giá Mua",
      field: "sellingPrice",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.sellingPrice) || '',
    },
    {
      name: "VAT",
      field: "vat",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.vat || '',
    },
    {
      name: "Thành Tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalAmount) || '',
    },
    {
      name: "Hạn Sử Dụng",
      field: "expiryDate",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => row?.expiryDate ? formatDateToDDMMYYYY(row?.expiryDate) : '',
    },
    {
      name: "Giá Bán",
      field: "salePrice",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.salePrice) || '',
    },
    {
      name: "Mã Lần Hiến",
      field: "donationCount",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row?.donationCount || '',
    },
    {
      name: "Mã ISBT",
      field: "labelCode",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row?.labelCode || '',
    },
  ];

  const columnsKH = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    ...(!isView ?
      [
        {
          name: "TT",
          field: "",
          headerStyle: {
            minWidth: 80,
            maxWidth: 80,
          },
          cellStyle: {
            textAlign: "center",
          },
          render: (row: any, index: number, stt: number) =>
            <div className="d-flex justify-content-center">
              {indexRowEdit !== index && <>
                <i
                  onClick={(e) => handleEdit(row, index)}
                  className="bi bi-pencil-square text-navy"
                ></i>
                <i
                  onClick={(e) => hanldeDeleteRow(index)}
                  className="bi bi-trash3-fill text-danger spaces ml-10  "
                ></i>
              </>}
            </div>
        },
      ] : []),

    {
      name: "Mã vật tư",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.code || row.item?.code || '',
    },
    {
      name: "Tên vật tư",
      field: "name",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.name || row.item?.name || '',

    },
    {
      name: "ĐVT",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.unitofMeasureName || row.item?.unitofMeasureName || '',
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalQuantity) || '',
    },
    {
      name: "Giá bán",
      field: "salePrice",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.salePrice) || '',
    },
    {
      name: "VAT",
      field: "vat",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.vat || '',
    },
    {
      name: "Chiết khấu (%)",
      field: "discountRate ",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.discountRate || '',
    },
    {
      name: "Giá thực",
      field: "",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => {
        let price = parseFloat(row.salePrice || 0);
        let vat = parseFloat(row.vat || 0);
        let discountRate = parseFloat(row.discountRate || 0);
        let realPrice = price * (1 - discountRate / 100);
        return formatMoney(realPrice * (1 + vat / 100));
      },
    },
    {
      name: "Thành tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalAmount) || '',

    },
  ];

  const columnsKhac = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    ...(!isView ?
      [
        {
          name: "TT",
          field: "",
          headerStyle: {
            minWidth: 80,
            maxWidth: 80,
          },
          cellStyle: {
            textAlign: "center",
          },
          render: (row: any, index: number, stt: number) =>
            <div className="d-flex justify-content-center">
              {indexRowEdit !== index && <>
                <i
                  onClick={(e) => handleEdit(row, index)}
                  className="bi bi-pencil-square text-navy"
                ></i>
                <i
                  onClick={(e) => hanldeDeleteRow(index)}
                  className="bi bi-trash3-fill text-danger spaces ml-10  "
                ></i>
              </>}
            </div>
        },
      ] : []),

    {
      name: "Mã vật tư",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.code || row.item?.code || '',
    },
    {
      name: "Tên vật tư",
      field: "name",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.name || row.item?.name || '',

    },
    {
      name: "ĐVT",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.unitofMeasureName || row.item?.unitofMeasureName || '',
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalQuantity) || '',
    },
    {
      name: "Giá mua",
      field: "sellingPrice",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.sellingPrice) || '',
    },
    {
      name: "VAT",
      field: "vat",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.vat || '',
    },
    {
      name: "Giá mua thực",
      field: "",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => {
        let price = parseFloat(row.sellingPrice || 0);
        let vat = parseFloat(row.vat || 0);
        return formatMoney(price * (1 + vat / 100));
      },
    },
    {
      name: "Thành tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalAmount) || '',

    },
  ];

  const columnsKhac_KHOMAU = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    ...(!isView ?
      [
        {
          name: "TT",
          field: "",
          headerStyle: {
            minWidth: 80,
            maxWidth: 80,
          },
          cellStyle: {
            textAlign: "center",
          },
          render: (row: any, index: number, stt: number) =>
            <div className="d-flex justify-content-center">
              {indexRowEdit !== index && <>
                <i
                  onClick={(e) => handleEdit(row, index)}
                  className="bi bi-pencil-square text-navy"
                ></i>
                <i
                  onClick={(e) => hanldeDeleteRow(index)}
                  className="bi bi-trash3-fill text-danger spaces ml-10  "
                ></i>
              </>}
            </div>
        },
      ] : []),

    {
      name: "Mã Máu/CP",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.code || row.item?.code || '',
    },
    {
      name: "Tên Máu/CP",
      field: "name",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.name || row.item?.name || '',

    },
    {
      name: "Đơn vị",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.unitofMeasureName || row.item?.unitofMeasureName || '',
    },
    {
      name: "Số lượng",
      field: "totalQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalQuantity) || '',
    },
    {
      name: "Giá mua",
      field: "sellingPrice",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.sellingPrice) || '',
    },
    {
      name: "VAT",
      field: "vat",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => row.vat || '',
    },
    {
      name: "Giá mua thực",
      field: "",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => {
        let price = parseFloat(row.sellingPrice || 0);
        let vat = parseFloat(row.vat || 0);
        return formatMoney(price * (1 + vat / 100));
      },
    },
    {
      name: "Thành tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row?.totalAmount) || '',

    },
  ];

  const columns = {
    [CODE_NHAP_KHO.NHA_CUNG_CAP]: warehouseId === WAREHOUSE_ID.KHO_MAU ? columnsNCC_KHOMAU : columnsNCC,
    [CODE_NHAP_KHO.TRA_LE]: columnsKH,
    [CODE_NHAP_KHO.KHAC]: warehouseId === WAREHOUSE_ID.KHO_MAU ? columnsKhac_KHOMAU : columnsKhac,
  };

  return (
    <div className="spaces mt-12 border-table custom-table">
      <div className="d-flex justify-content-between">
        <h4 className="spaces mt-8 ml-10 title">Thông tin nhập { warehouseId === WAREHOUSE_ID.KHO_MAU ? "máu" : "thuốc" }</h4>
        <h4 className="spaces mt-8 ml-10 mr-80 title">{`Tổng thành tiền: ${formatMoney(dsNhapKho.reduce((sum: number, item: any) => sum + (Number(item.totalAmount) || 0), 0))}`}</h4>
      </div>
      <OctTable
        id="table-tt-nhap-thuoc"
        className={`spaces custom-header-table ${isView ? 'table-view-tt-nhap-thuoc' : 'table-tt-nhap-thuoc'}`}
        columns={columns[type.code]}
        data={dsNhapKho}
        noToolbar
        clearToolbar
        noPagination
        fixedColumnsCount={-1}
      />
    </div>
  );
};

export default TableThongTinNhapThuoc;
