import QuanLyKho from "../component/quan-ly-kho/QuanLyKho";
import PhanHeDieuChuyen from "../phan-he-dieu-chuyen/PhanHeDieuChuyen";
import PhanHeNhapKho from "../phan-he-nhap-kho/PhanHeNhapKho";
import BoSungCoSoTuTruc from "../phan-he-tu-truc/components/BoSungCoSoTuTruc";
import PhanHeXuatKho from "../phan-he-xuat-kho/PhanHeXuatKho";
import { CODE_FORM, TYPE_WH, WAREHOUSE_ID } from "../utils/Constant";
import DuTruBoSungVTKho from "../du-tru-bo-sung-vat-tu-kho/DuTruBoSungVTKho";
import TongHopBoSungVatTu from "../TongHopBoSungVatTu";

export const danhSachTabKhoVatTu = [
    {
        eventKey: "0",
        title: "Xem tồn kho",
        component: <QuanLyKho warehouseId={WAREHOUSE_ID.VAT_TU} />,
    },
    {
        eventKey: "1",
        title: "Nhập kho",
        component: <PhanHeNhapKho warehouseId={WAREHOUSE_ID.VAT_TU}/>,
    },
    {
        eventKey: "2",
        title: "Xuất kho",
        component: <PhanHeXuatKho warehouseId={WAREHOUSE_ID.VAT_TU}/>,
    },
    {
        eventKey: "3",
        title: "Điều chuyển",
        component: <PhanHeDieuChuyen warehouseId={WAREHOUSE_ID.VAT_TU}/>,
    },
    {
        eventKey: "4",
        title: "Lĩnh về tủ trực",
        component: <BoSungCoSoTuTruc modelId={{
            id: CODE_FORM.BO_SUNG_CO_SO_TU_TRUC,
            type: TYPE_WH.BO_SUNG_CO_SO_TU_TRUC,
            name: 'Danh sách vật tư',
            title: '',
            whIssueId: WAREHOUSE_ID.VAT_TU,
        }} />,
    },
    { // RHM
        eventKey: "6",
        title: "Tổng hợp dự trù",
        component: <TongHopBoSungVatTu
            modelId={{
                id: CODE_FORM.TONG_HOP_VAT_TU,
                type: 15,
                name: 'Tổng hợp dự trù',
                title: 'phiếu tổng hợp'
            }}
            // onlyNhapKho={true}
            warehouseId={WAREHOUSE_ID.VAT_TU}
        />,
    },
    {
        eventKey: "7",
        title: "Dự trù bổ sung vật tư",
        component: <DuTruBoSungVTKho warehouseId={WAREHOUSE_ID.VAT_TU} />
    },
];

export {};