import { FC, Suspense, lazy } from "react";
import { useRef, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import PhanHeQuanTriHeThong from "../modules/phan-he-quan-tri-he-thong/PhanheQuanTriHeThong";
import PhanHeThongKe from "../modules/phan-he-thong-ke/PhanHeThongKe";
import PhanHeNhapKho from "../modules/phan-he-nhap-kho/PhanHeNhapKho";
import PhanHeXuatKho from "../modules/phan-he-xuat-kho/PhanHeXuatKho";
import { HomePage } from "../pages/Homepage/HomePage";
import { MenuTestPage } from "../pages/MenuTestPage";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import PhanHeDieuChuyen from "../modules/phan-he-dieu-chuyen/PhanHeDieuChuyen";
import QuanLyThau from "../modules/quan-ly-thau/QuanLyThau";
import PhanHeTuTruc from "../modules/phan-he-tu-truc/PhanHeTuTruc";
import KhoVatTu from "../modules/kho-vat-tu-ngoai-tru/KhoVatTu";
import KhoNhaThuoc from "../modules/kho-nha-thuoc/KhoNhaThuoc";
import PhanHeVienPhi from "../modules/phan-he-thu-ngan/PhanHeThuNgan";
import TheKho from "../modules/the-kho/TheKho"
import KhoNoiVien from "../modules/kho-noi-vien/KhoNoiVien";
import KhoYCu from "../modules/kho-y-cu/KhoYCu";
import KhoMau from "../modules/kho-mau/KhoMau";
import KhoHoaChat from "../modules/kho-hoa-chat/KhoHoaChat";
import DanhMuc from "../modules/danh-muc/DanhMuc";
import KiemKe from "../modules/kiem-ke/KiemKe";
import { useNavigate } from "react-router-dom";
import { localStorageItem } from "../../app/modules/utils/LocalStorage";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const listMenu = localStorageItem.get("id-token-decode")?.menus?.["oceantech-wms-server"]
  useEffect(() => {
    if (!listMenu?.[0]?.subs?.length) {
      navigate("/error/403");
    } else {
      // navigate(`${listMenu?.[0]?.subs?.[0]?.uri}`);
    }
  }, [listMenu?.[0]?.subs?.length])
  return (
    <Routes>
      <Route index element={<Navigate to={`${listMenu?.[0]?.subs?.[0]?.uri}`} />} />
      <Route path="/*" element={<Navigate to={`${listMenu?.[0]?.subs?.[0]?.uri}`} />} />
      {/* <Route index element={<Navigate to="/home" />} />
      <Route path="/*" element={<HomePage />} /> */}

      <Route path="home" element={<HomePage />} />
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/home" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* {
          listMenu[0]?.subs.map((item?: any, index?: any) => {
            // const DataComponent = item?.component
            return <Route path={item?.uri} element={<PhanHeVienPhi />} />
          })
        } */}
        <Route path="system/warehouse/cashier" element={<PhanHeVienPhi />} />
        <Route path="system/warehouse/statistics" element={<PhanHeThongKe />} />
        <Route path="system/warehouse/card" element={<TheKho />} />
        <Route path="system/warehouse/stocktaking" element={<KiemKe />} />
        <Route path="system/warehouse/cabinet" element={<PhanHeTuTruc />} />
        <Route path="system/warehouse/materials-storage" element={<KhoVatTu />} />
        <Route path="system/warehouse/pharmacy" element={<KhoNhaThuoc />} />
        <Route path="system/warehouse/internal-hospital" element={<KhoNoiVien />} />
        <Route path="system/warehouse/blood" element={<KhoMau />} />
        <Route path="system/warehouse/medical-equipment" element={<KhoYCu />} />
        <Route path="system/warehouse/catalog" element={<DanhMuc />} />
        <Route path="system/warehouse/chemical-storage" element={<KhoHoaChat />} />
        {/* Lazy Modules */}
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>

  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

