import * as Yup from "yup";
import { Formik } from "formik";
import { SetStateAction, createContext, useState } from "react";
import FormDuTruBoSungVTKho from "./components/FormDuTruBoSungVTKho";

export interface DuTruBoSungVTKhoContextProps {
  dsNhapKho: any;
  setDsNhapKho: SetStateAction<any>;
}

const initialContext = {
  dsNhapKho: [],
  setDsNhapKho: () => {},
};

export const DuTruBoSungVTKhoContext = createContext<DuTruBoSungVTKhoContextProps>(initialContext);
interface Props {
  warehouseId: number;
}
export const DuTruBoSungVTKho = (props: Props) => {
  const { warehouseId } = props;
  const [dsNhapKho, setDsNhapKho] = useState([]);

  let validationSchema = Yup.object({});
  const handleFormSubmit = (values: any) => {};

  return (
    <Formik<any>
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      <DuTruBoSungVTKhoContext.Provider
        value={{
          dsNhapKho: dsNhapKho,
          setDsNhapKho: setDsNhapKho,
        }}
      >
        <div className="bg-white">
          <div className="flex-1 flex flex-column">
            <div className="py-1">
              <FormDuTruBoSungVTKho
                warehouseId={warehouseId}
              />
            </div>
          </div>
        </div>
      </DuTruBoSungVTKhoContext.Provider>
    </Formik>
  );
};

export default DuTruBoSungVTKho;
