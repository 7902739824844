import axios from "axios";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_SLIP = API + "/slip";

export const searchDsPhieuDuTru = (searchData: any) => {
  const url = `${API_SLIP}/search`;
  return axios.post(url, searchData);
};

export const deletePhieuDuTru = (id: string) => {
  const url = `${API_SLIP}/${id}`;
  return axios.delete(url);
};

export const getDsThuocDuTru = (searchData: any) => {
  const url = `${API}/inventory-item/search`;
  return axios.post(url, searchData);
};

export const updatePhieuDuTru = (id: string, data: any) => {
  const url = `${API_SLIP}/themPhieuDuTruBoSungVatTu/${id}`;
  return axios.put(url, data);
};

export const getLstItemTongHop = (lstId: string[]) => {
  const url = `${API_SLIP}/inventory-aggregation`;
  return axios.post(url, { ids: lstId });
};
