import { FC, useContext, useEffect, useState } from "react";
import InputSearch from "../../component/InputSearch";
import SelectTree from "../../component/SelectTree";
import { TreeVatTu, VT_THUOC,DanhSachMauColumns, DanhSachVatTuColumns } from "../constants/ColumnsThongKe";
import DialogThemMoiVatTu from "./ThemMoiVatTu/DialogThemMoiVatTu";
import { AppContext } from "../../appContext/AppContext";
import { getListFormTab } from "../services/ThongKeServices";
import { CODE, KEY, SELECTION_MODE } from "../../utils/Constant";
import "../PhanHeThongKe.scss";
import { saveAs } from "file-saver";
import TabThongTinVatTu from "./FormTabGererate/tab-auto-generate/TabThongTinAutoGenerate";
import CustomDropdownButtonChoose from "../../component/CustomDropdownButtonChoose";
import { DanhSachVatTuInterface } from "../models/ThongKeModel"
import { Column } from "react-table";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { Button } from "react-bootstrap";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { DEFAULT_PAGE_INDEX } from "../../utils/Constant";
import { searchByPage, deleteItem, exportDSThuocVT, getDetailVatTuEdit, importDSThuocVT, getListNhomVatTu } from "../services/ThongKeServices";
import { PhanHeThongKeContextProps, PhanHeThongKeContext } from "../PhanHeThongKe"
import { IconMenu } from "../../component/IconSvg";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { TablePagination } from "../../component/table/components/TablePagination";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import TextField from "../../component/TextField";


export const FormThongKe: FC = () => {
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [treeData, setTreeData] = useState<any>(TreeVatTu);
  const [listVatTu, setListVatTu] = useState<any[]>();
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState<any>(false);
  const { setIsLoading } = useContext(AppContext);
  const [listTab, setListTab] = useState([]);
  const [itemSelected, setItemSelected] = useState<any>();
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
  const [listNhomVatTu, setListNhomVatTu] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [searchData, setSearchData] = useState<any>({});
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [columns, setColumns] = useState<any>(DanhSachVatTuColumns);

  const { dataVatTuView, setDataVatTuView, nhomVatTu, setNhomVatTu, setDataVatTuEdit } = useContext<PhanHeThongKeContextProps>(PhanHeThongKeContext);
  const COLUMNS = {
    thongTinChung: DanhSachVatTuColumns,
    thongTinChungVatTu: DanhSachVatTuColumns,
    thongTinChungMau: DanhSachMauColumns,
  }
  useEffect(() => {
    const modelIdKey = nhomVatTu?.description as keyof typeof COLUMNS;
    if (nhomVatTu?.description) {
      setColumns(COLUMNS[modelIdKey])
    }
  }, [nhomVatTu?.description]);
  useEffect(() => {
    if (nhomVatTu?.id) {
      updatePageData();
      getListform();
    }
  }, [page, rowsPerPage, nhomVatTu?.id, searchData.materialType]);
  useEffect(() => {
    getListVatTu();
  }, []);
  const handleGetSelectedItem = (data: any) => {
    setSearchData({
      ...searchData,
      materialType: data?.code === searchData.materialType ? null : data?.code,
    });
  };
  const handleConvertTreeData = (data: any[]) => {
    let convertedData: any[] = data?.map((item, index) => {
      return {
        ...item,
        code: item?.code || item?.name,
        name: item?.name,
        ...(item?.subs &&
          item?.subs?.length && {
          filter: handleConvertTreeData(item?.subs || []),
        }),
      };
    });
    return convertedData;
  };
  const getListVatTu = async () => {
    try {
      let { data } = await getListNhomVatTu();
      let ListData = data?.data?.map((item: any) => {
        return {
          ...item,
          name: "Nhóm vật tư - " + item?.name,
          defauiltName: item?.name,
        }
      })
      setListNhomVatTu(ListData);
    } catch (error) {
    } finally {
    }
  }

  const handlerSearchKeyWorld = () => {
    setPage(DEFAULT_PAGE_INDEX);
    setRowsPerPage(20);
    updatePageData()
  }
  const updatePageData = async () => {
    try {
      setIsLoading(true);
      let { data } = await searchByPage({
        pageIndex: page,
        pageSize: rowsPerPage,
        materialGroup: nhomVatTu?.id,
        materialType: searchData.materialType || null,
        keyword: searchData.keyword?.trim() || null,
      });
      if (CODE.SUCCESS === data?.code) {
        setListVatTu(data?.data?.content);
        setConfigTable({
          totalPages: (data.data.pageable.totalElements / data.data.pageable.pageSize).toFixed(),
          totalElements: data?.data?.pageable?.totalElements,
          // numberOfElements: data?.data?.pageable?.pageNumber,
        })
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getListform = async () => {
    try {
      let { data } = await getListFormTab("VIEW", nhomVatTu);
      if (CODE.SUCCESS === data?.code) {
        let ListData = data?.data?.map((item: any) => {
          return {
            ...item,
            component: <TabThongTinVatTu ThongTinTab={item || {}} dataVatTu={nhomVatTu} />
          }
        })
        setListTab(ListData)
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handlerCloseDialogAdd = () => {
    updatePageData();
    setOpenDialogThemMoi(false);
    handlerReset();
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await deleteItem(itemSelected.id);
      if (CODE.SUCCESS === res.status) {
        toast.success("Xóa vật tư thành công");
        setOpenDialogConfirmDelete(false);
        updatePageData();
      }
    } catch (error: any) {
      const message = error?.message || "Có lỗi xảy ra, vui lòng thử lại";
      toast.error(message)
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getDataVatTuEdit = async (value?: any) => {
    const values = {
      id: value?.id
    }
    try {
      let { data } = await getDetailVatTuEdit(values);
      if (CODE.SUCCESS === data?.code) {
        // const thongTinChung = {
        //   ...data?.data.thongTinChung,
        //   ...data?.data.thongTinChungVatTu,
        // }
        setDataVatTuEdit({
          ...data?.data,
          // [nhomVatTu?.description]: data?.data?.[nhomVatTu?.description]
        })
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handlerReset = () => {
    setDataVatTuView({});
    setDataVatTuEdit({});
    setListVatTu([]);
  }
  const handlerCloseDialogDelete = () => {
    setItemSelected({})
    setOpenDialogConfirmDelete(false);
  }
  const handleEdit = (values: any) => {
    values?.id && getDataVatTuEdit(values);
    setOpenDialogThemMoi(true)
  }
  const handleOpenDialog = () => {
    handlerReset()
    setOpenDialogThemMoi(true)
  }
  const handlerClickItem = (item: any) => {
    handlerReset();
    setNhomVatTu(item)
    let treeDataConverted = {
      code: "all",
      name: "Tất cả dịch vụ",
      icon: <IconMenu />,
      filter: handleConvertTreeData(item?.terms || []),
    };
    setTreeData(treeDataConverted);
  }
  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center gap-3">
        <>
          <CustomIconButton
            variant="edit"
            onClick={() => handleEdit(props.cell.row.original)}
          >
            <i className="bi bi-pencil-square text-navy"></i>
          </CustomIconButton>
          {!props.cell.row.original.isWarehouseReceipt &&
            <CustomIconButton
              variant="delete"
              onClick={() => {
                setItemSelected(props.cell.row.original);
                setOpenDialogConfirmDelete(true);
              }}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          }
        </>
      </div>
    )
  };

  const handlerImportDSThuocVT = async (e: any) => {
    if (!e.target.files[0]) return;
    try {
      setIsLoading(true);
      const file = e.target.files[0];
      const { data } = await importDSThuocVT(file, nhomVatTu?.id);
      if (CODE.SUCCESS === data.code) {
        updatePageData()
        toast.success('Thêm danh sách thành công');
        // getDanhSachBenhNhanh();
      } else {
        // toast.error(data?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleExportDSThuocVT = async () => {
    try {
      setIsLoading(true);
      const res = await exportDSThuocVT(nhomVatTu?.id);
      if (CODE.SUCCESS === res.status) {
        saveAs(res.data, `Danh sách ${nhomVatTu?.defauiltName}.xlsx`)
        toast.success('Lưu danh sách  thành công')
      };
    } catch (error) {
      console.error(error);
      toast.error('Có lỗi xảy ra, vui lòng thử lại !')
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white">

      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-20 mr-5 bg-white h-calc-vh-54 radius-2">
            <div className="spaces px-10 pt-4 h-40">
              <CustomDropdownButtonChoose
                drop="down"
                listMenuItems={listNhomVatTu.map((item: any) => ({
                  ...item,
                }))}
                onClickItem={handlerClickItem}
                label="Chọn nhóm vật tư"
                className="spaces w-100 ml-1"
                menuItemsClassName="w-100 mx-6"
              />
            </div>
            <SelectTree
              className="w-100 h-100 border  px-0  border-0 overflow-auto"
              codeCollapses={codeCollapses}
              handleChangeCollapsesCode={setCodeCollapses}
              idSelected={idSelected}
              handleChangeSelectId={setIdSelected}
              getSelectedItem={handleGetSelectedItem}
              selectTree={treeData}
            />
          </div>
          <div className="spaces width-80 bg-gray radius-2">
            <div className="flex bg-white  spaces width-100 justify-content-between" >
              <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0  px-10">
                {`Danh sách ${nhomVatTu?.defauiltName || ""} `}
              </h4>
            </div>
            <div className="d-flex  mt-5 flex-column spaces width-100">
              <div className="bg-white">
                <div className="d-flex spaces px-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  <div className=" bg-white flex align-items-center ">
                    <Button className="btn-fill mx-2 mr-5 spaces min-w-90px btn btn-primary" onClick={handleOpenDialog}>
                      <i className='bi bi-plus-lg text-pri fs-3'></i>
                      Thêm
                    </Button>
                  </div>
                  <div className=" bg-white flex align-items-center mr-5 spaces">
                    <Button className="btn-fill position-relative spaces " >
                      {/* <i className='bi bi-plus-lg text-pri fs-3'></i> */}
                      Thêm danh sách
                      <TextField
                        className="btn-file"
                        type="file"
                        name="fileDSNhanVien"
                        onChange={(e: any) => handlerImportDSThuocVT(e)}
                      />
                    </Button>
                    <Button className="btn-fill ml-5 spaces" onClick={handleExportDSThuocVT}>
                      Xuất mẫu
                    </Button>
                  </div>

                  <div className="flex-grow-1 ">
                    <InputSearch
                      placeholder="Tìm kiếm theo mã hoặc tên thuốc/vật tư"
                      className="spaces h-31 pr-4  radius-3"
                      handleChange={(e) => setSearchData({ ...searchData, keyword: e.target.value })}
                      handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && handlerSearchKeyWorld()}
                      handleSearch={() => handlerSearchKeyWorld()}
                    />
                  </div>
                </div>
                <div className=" spaces bg-white mb-0 mt-6">
                  <TableCustom<DanhSachVatTuInterface>
                    className="spaces h-bang-ds-vt"
                    data={listVatTu || []}
                    columns={columns}
                    columnsActions={columnsActions}
                    isClearSelect={!dataVatTuView?.id}
                    selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                    verticalScroll={true}
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={Number(configTable.totalPages)}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    className="border border-0"
                  />
                </div>
              </div>
            </div>
            <div className="spaces w-100 d-flex justify-content-end bg-white mt-2 gap-6 py-8 z-index-2"></div>
          </div>
          {openDialogThemMoi &&
            <DialogThemMoiVatTu show={openDialogThemMoi} onHide={handlerCloseDialogAdd} />
          }
          {openDialogConfirmDelete &&
            <ConfirmDialog
              show={openDialogConfirmDelete}
              onCloseClick={handlerCloseDialogDelete}
              onYesClick={handleDelete}
              title="Xóa vật tư"
              message={`Bạn chắc chắn muốn xóa vật tư: ${itemSelected.name} ?`}
              yes="Xóa"
              close="Hủy"
            />
          }
        </div>
      </div>
    </div>
  )
}

export default FormThongKe;
