import { Formik, useFormikContext } from "formik";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import { OctTable } from "@oceantech/oceantech-ui";
import { CODE, WAREHOUSE_ID } from "../../utils/Constant";
import { formatMoney } from "../../utils/FormatUtils";
import { AppContext } from "../../appContext/AppContext";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { generateForm, generateInitValues, generateValidationSchema, roundToDecimalPlaces } from "../../utils/AppFunction";
import { searchListItemGoiThau } from "../DanhMucServices";

interface Props {
  isView?: boolean;
  type: any;
  dataEdit: any;
  lstVatTu: any[];
  indexRowEdit: number | null;
  setLstVatTu: Dispatch<SetStateAction<any[]>>;
  setIndexRowEdit: Dispatch<SetStateAction<number | null>>;
  warehouseId?: number;
}

interface PropsVatTu {
  modelID: string
}

const HandleValues = (props: PropsVatTu) => {
  const { modelID } = props;
  const { values, setFieldValue } = useFormikContext<any>();
  const data = values[modelID];

  const handleTotalAmount = (quantity: number, price: number) => {
    const result = quantity * price;
    return roundToDecimalPlaces(result);
  };

  useEffect(() => {
    setFieldValue(modelID, {
      ...data,
      totalAmount: handleTotalAmount(data.totalQuantity, data.sellingPrice),
    })
  }, [data?.totalQuantity, data?.sellingPrice]);


  return <></>
};

const FormVatTuDanhMuc = (props: Props) => {
  const {
    isView,
    type,
    dataEdit,
    lstVatTu,
    setLstVatTu,
    indexRowEdit,
    setIndexRowEdit,
    warehouseId = WAREHOUSE_ID.NHA_THUOC,
  } = props;
  const { setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [initValues, setInitValues] = useState<any>({});

  const updateForm = async () => {
    const data = await generateForm(type?.description?.formVatTu, setIsLoading);
    setInitValues(generateInitValues(data));
    setListJsonGenerate(data || []);
  };

  const hasDuplicate = (lst: any[], indexRowEdit: number | null) => {
    const seen = new Map();
    for (let i = 0; i < lst.length; i++) {
        const item = lst[i];
        const key = `${item.itemId}-${item.supplier}`;
        // Nếu key đã tồn tại trong Map
        if (seen.has(key)) {
            const existingIndex = seen.get(key);
            // Bỏ qua nếu chỉ số hiện tại bằng indexRowEdit
            if (existingIndex !== indexRowEdit) {
                return true; // Có item trùng itemId và supplier
            }
        }
        // Lưu key và index vào Map
        seen.set(key, i);
    }

    return false; // Không có item nào trùng itemId và supplier
};

  const handleAddItem = (values: any) => {
    const dataItem = values[type?.description?.formVatTu] || {};
    const errrorMessage = !dataItem.code
      ? "Chưa chọn thuốc/VT"
      : !dataItem.totalQuantity
      ? "Chưa nhập số lượng"
      : parseFloat(dataItem.totalQuantity) <= 0
      ? "Số lượng phải lớn hơn 0"
      : hasDuplicate([...lstVatTu, dataItem], indexRowEdit)
      ? "Vật tư và nhà cung cấp đã tồn tại"
      : null;

    if (errrorMessage) {
      toast.warning(errrorMessage);
      return;
    }

    let newLstItem = [...lstVatTu];
    if (indexRowEdit === null) {
      newLstItem = [
        ...newLstItem,
        {
          ...dataItem,
          initialQuantity: dataItem.totalQuantity,
          id: null,
          itemId: dataItem.itemId,
          warehouseId: warehouseId,
        },
      ];
    } else {
      newLstItem[indexRowEdit] = {
        ...dataItem,
        initialQuantity: dataItem.totalQuantity,
        id: newLstItem[indexRowEdit]?.id,
        itemId: dataItem.itemId || dataItem.item?.id,
        warehouseId: warehouseId,
      };
    }
    setIndexRowEdit(null);
    setInitValues(generateInitValues(listJsonGenerate));
    setLstVatTu(newLstItem);
  };

  const handleEditItem = (item: any, index: number) => {    
    setIndexRowEdit(index);
    setInitValues({ ...initValues, ...item });
  };

  const handleDeleteItem = (index: number) => {
    const data = [...lstVatTu].filter((_: any, i: number) => i !== index);
    setLstVatTu(data);
  };

  const handleGetDetail = async () => {    
    try {
      setIsLoading(true);
      const { data } = await searchListItemGoiThau(dataEdit.id);
      if (data.code === CODE.SUCCESS) {
        setLstVatTu(data.data.content);
      } else {
        toast.error('Lỗi tải danh sách vật tư, vui lòng thử lại');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    type.description?.formVatTu && updateForm();
  }, [type.description?.formVatTu]);

  useEffect(() => {    
    dataEdit?.id && handleGetDetail(); 
  }, [dataEdit?.id]);

  const columns = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    ...(!isView
      ? [
          {
            name: "TT",
            field: "",
            headerStyle: {
              minWidth: 80,
              maxWidth: 80,
            },
            cellStyle: {
              textAlign: "center",
            },
            render: (row: any, index: number, stt: number) => (
              <div className="d-flex justify-content-center">
                {indexRowEdit !== index && (
                  <>
                    <i
                      onClick={(e) => handleEditItem(row, index)}
                      className="bi bi-pencil-square text-navy"
                    ></i>
                    <i
                      onClick={(e) => handleDeleteItem(index)}
                      className="bi bi-trash3-fill text-danger spaces ml-10  "
                    ></i>
                  </>
                )}
              </div>
            ),
          },
        ]
      : []),

    {
      name: "Mã vật tư",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.code || row.item?.code || "",
    },
    {
      name: "Tên vật tư",
      field: "name",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.name || row.item?.name || "",
    },
    {
      name: "ĐVT",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.unitofMeasureName || row.item?.unitofMeasureName || "",
    },
    {
      name: "SL thầu",
      field: "initialQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        formatMoney(row?.initialQuantity) || "",
    },
    {
      name: "SL còn lại",
      field: "totalQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        formatMoney(row?.totalQuantity) || "",
    },
    {
      name: "Giá thầu",
      field: "sellingPrice",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        formatMoney(row?.sellingPrice) || "",
    },
    {
      name: "Thành tiền",
      field: "totalAmount",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        formatMoney(row?.totalAmount) || "",
    },
    {
      name: "Gói thầu",
      field: "biddingPackage",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
    },
    {
      name: "Nhà cung cấp",
      field: "supplierName",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{ [type?.description?.formVatTu]: initValues }}
      validationSchema={generateValidationSchema(
        listJsonGenerate,
        type?.description?.formVatTu
      )}
      onSubmit={handleAddItem}
    >
      {({
        values,
        touched,
        errors,
        setValues,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="spaces px-10">
            <GenerateFormComponent
              isView={isView}
              listFieldAuto={listJsonGenerate}
              handleChange={handleChange}
              setValues={setValues}
              values={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              modelID={type?.description?.formVatTu}
              isNhapKho={true}
              customComponent={DynamicComponent}
              warehouseId={warehouseId}
              propsRenderButton={(data: any) =>
                setInitValues({ ...initValues, ...data })
              }
              DynamicClass={"pl-44"}
            />
            <HandleValues modelID={type?.description?.formVatTu} />
          </div>

          <div className=" d-flex w-100 spaces py-6 pl-5 mt-10">
            {!isView &&
              <Button
                onClick={(e) => handleSubmit()}
                className="btn btn-fill min-w-120px spaces ml-5"
              >
                {indexRowEdit === null ? "Thêm thuốc/VT" : "Cập nhật thuốc/VT"}
              </Button>
            }
          </div>
          <OctTable
            id="table-vat-tu-danh-muc"
            className="custom-header-table spaces h-400"
            columns={columns}
            data={lstVatTu}
            noToolbar
            clearToolbar
            noPagination
            fixedColumnsCount={-1}
          />
        </Form>
      )}
    </Formik>
  );
};

export default FormVatTuDanhMuc;
