import { useFormikContext } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import ThongTinPhieu from "./ThongTinPhieu";
import ThongTinXuatThuoc from "./ThongTinXuatThuoc";
import { PhieuXuat } from "../../../models/XuatKhoModel";
import WfButton from "../../../../workflows/components/WfButton";
import { WF_TRACKER } from "../../../../utils/Constant";
import FormThemVatTu from "./FormThemVatTu";
import { WMS_STATUS } from "../../../constants/constantsXuatKho";
import { PhanHeXuatKhoContext } from "../../../PhanHeXuatKho";
import { EXPORT_CODE } from "../../../constants/constantsXuatKho";
import ButtonIn from "../../../../component/button-in-phieu";
import { inPhieuXuatKhachHang, inPhieuXuatKhac, inPhieuXuatHuy, inPhieuXuatNCC, inPhieuXuatHaoPhi } from "../../../services/servicesXuatKho";

type Props = {
  isReload?: boolean;
  onHide: () => void;
  warehouseExportType?: any;
  warehouseId: number;
  listJsonGeneratePhieuXuat?: any;
};

const FormThemMoi: FC<Props> = (props) => {
  const { warehouseExportType, listJsonGeneratePhieuXuat, warehouseId } = props;
  const { thongTinPhieu } = useContext(PhanHeXuatKhoContext);
  const { handleSubmit, values } = useFormikContext<PhieuXuat>();
  const [statusPhieu, setStatusPhieu] = useState<any>(false);
  const [isView, setIsView] = useState(false);

  const renderButton = () => {
    if (EXPORT_CODE.KHACH_HANG == warehouseExportType?.code) {
      return <>
        {!thongTinPhieu?.phieuXuatKhoAdd?.id &&
          <>
            <Button type="submit" className="btn btn-fill min-w-100px spaces mr-10 min-w-100">
              <i className="bi bi-floppy"></i>
              Lưu
            </Button>
            {(thongTinPhieu?.phieuXuatKhoAdd?.id) &&
              <ButtonIn
                className="spaces min-w-120 mx-3"
                fetchExport={inPhieuXuatKhachHang}
                params={{
                  slipId: thongTinPhieu?.phieuXuatKhoAdd?.id,
                }}
              />
            }
          </>
        }
      </>
    } else {
      return <>
        <WfButton
          tracker={WF_TRACKER.XUAT_KHO}
          objectId={values?.phieuXuatKhoAdd?.id}

          additionalFunc={(data) =>
            setStatusPhieu({
              status: data?.currCode
            })
          }
        />
        {WMS_STATUS.warehouse_approved != statusPhieu?.status &&
          <Button type="submit" className="btn btn-fill min-w-120 spaces mx-3 min-w-100">
            <i className="bi bi-floppy"></i>
            Lưu
          </Button>
        }
        {(thongTinPhieu?.phieuXuatKhoAdd?.id && warehouseExportType?.code === EXPORT_CODE.NCC) &&
          <ButtonIn
            className="spaces min-w-120 mx-3"
            fetchExport={inPhieuXuatNCC}
            params={{
              slipId: thongTinPhieu?.phieuXuatKhoAdd?.id,
            }}
          />
        }
        {(thongTinPhieu?.phieuXuatKhoAdd?.id && warehouseExportType?.code === EXPORT_CODE.HUY) &&
          <ButtonIn
            className="spaces min-w-120 mx-3"
            fetchExport={inPhieuXuatHuy}
            params={{
              slipId: thongTinPhieu?.phieuXuatKhoAdd?.id,
            }}
          />
        }
        {(thongTinPhieu?.phieuXuatKhoAdd?.id && warehouseExportType?.code === EXPORT_CODE.KHAC) &&
          <ButtonIn
            className="spaces min-w-120 mx-3"
            fetchExport={inPhieuXuatKhac}
            params={{
              slipId: thongTinPhieu?.phieuXuatKhoAdd?.id,
            }}
          />
        }
        {(thongTinPhieu?.phieuXuatKhoAdd?.id && warehouseExportType?.code === EXPORT_CODE.HAO_PHI) &&
          <ButtonIn
            className="spaces min-w-120 mx-3"
            fetchExport={inPhieuXuatHaoPhi}
            params={{
              slipId: thongTinPhieu?.phieuXuatKhoAdd?.id,
            }}
          />
        }
      </>
    }
  };

  useEffect(() => {
    const isView =
      (values.phieuXuatKhoAdd?.statusId && values.phieuXuatKhoAdd?.statusId === WMS_STATUS.warehouse_approved)
      || statusPhieu.status === WMS_STATUS.warehouse_approved;

    setIsView(isView);
  }, [statusPhieu, values.phieuXuatKhoAdd?.statusId]);

  return (
    <div className="bg-gray">
      <Form className="receive" onSubmit={handleSubmit}>
        <div className="spaces width-100 ">
          <ThongTinPhieu
            modelID="phieuXuatKhoAdd"
            listJsonGeneratePhieuXuat={listJsonGeneratePhieuXuat}
            isView={isView}
          />
          <FormThemVatTu
            isView={isView}
            statusPhieu={statusPhieu}
            warehouseExportType={warehouseExportType}
            inventoryId={values.phieuXuatKhoAdd?.inventoryId}
            id={values.phieuXuatKhoAdd?.id}
            warehouseId={warehouseId}
            generalValues={values}
          />
          <ThongTinXuatThuoc statusPhieu={statusPhieu} id={values.phieuXuatKhoAdd?.id} warehouseExportType={warehouseExportType} warehouseId={warehouseId}/>
        </div>
        <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6 pl-10">
          {renderButton()}
        </div>
      </Form>
    </div>
  );
};
export default FormThemMoi;
