import { PHIEU_IN_ENDPOINT } from "../../utils/Constant";
import { localStorageItem } from "../../utils/LocalStorage";
import { TypeNhapKho } from "../models/NhapKhoModel";
const dataUser = localStorageItem.get("current-user") ? localStorageItem.get("current-user") : {};

export const WMS_STATUS = {
  warehouse_approved: "warehouse_approved",
  warehouse_approved_id: 11,
  warehouse_start: "warehouse_start",
  warehouse_approve: "warehouse_approve",
  warehouse_new: "warehouse_new",
  id_warehouse_new: 10,
  warehouse_export_approved: "warehouse_export_approved",
  warehouse_export_start: "warehouse_export_start",
  id_warehouse_wait_import: 3,
};

export const CODE_NHAP_KHO = {
  NHA_CUNG_CAP: 'X004',
  TRA_LE: 'X003',
  KHAC: 'X005',
}

export const TYPE_NHAP_KHO: TypeNhapKho[] = [
  {
    name: 'Nhập lại Khách hàng',
    code: CODE_NHAP_KHO.TRA_LE,
    formThongTinChung: 'phieuNhapKhoKH',
    formVatTu: 'themVatTuPhieuNhapKhoKH',
    pathApi: 'phieuNhapKhoKH',
    pathApiPrint: PHIEU_IN_ENDPOINT.NHAP_KHO.TRA_LE,
  },
  {
    name: 'Nhập nhà cung cấp',
    code: CODE_NHAP_KHO.NHA_CUNG_CAP,
    formThongTinChung: 'phieuNhapKhoAdd',
    formVatTu: 'themVatTuPhieuNhapKho',
    pathApi: 'phieuNhapKhoAdd',
    pathApiPrint: PHIEU_IN_ENDPOINT.NHAP_KHO.NHA_CUNG_CAP,
  },
  {
    name: 'Nhập khác',
    code: CODE_NHAP_KHO.KHAC,
    formThongTinChung: 'formNhapKhac',
    formVatTu: 'themVatTuPhieuNhapKhoNK',
    pathApi: 'formNhapKhac',
    pathApiPrint: PHIEU_IN_ENDPOINT.NHAP_KHO.KHAC,
  },
  // {
  //   name: 'Nhập nhà cung cấp (RHM)',
  //   code: CODE_NHAP_KHO.NHA_CUNG_CAP,
  //   formThongTinChung: 'phieuNhapKhoAdd',
  //   formVatTu: 'themvtnhapkhorhm',
  //   pathApi: 'phieuNhapKhoAdd',
  //   pathApiPrint: PHIEU_IN_ENDPOINT.NHAP_KHO.NHA_CUNG_CAP,
  // },
];

export const TYPE_NHAP_KHO_MAU: TypeNhapKho[] = [
  {
    name: 'Nhập nhà cung cấp',
    code: CODE_NHAP_KHO.NHA_CUNG_CAP,
    formThongTinChung: 'nhapKhoKhoMau',
    formVatTu: 'themVTNhapKhoKhoMau',
    pathApi: 'nhapKhoKhoMau',
    pathApiPrint: PHIEU_IN_ENDPOINT.NHAP_KHO.NHA_CUNG_CAP,
  },
  {
    name: 'Nhập khác',
    code: CODE_NHAP_KHO.KHAC,
    formThongTinChung: 'nhapKhoKhacKhoMau',
    formVatTu: 'themVatTuPhieuNhapKhoNKKhoMau',
    pathApi: 'nhapKhoKhacKhoMau',
    pathApiPrint: PHIEU_IN_ENDPOINT.NHAP_KHO.KHAC,
  },
  // {
  //   name: 'Nhập nhà cung cấp (RHM)',
  //   code: CODE_NHAP_KHO.NHA_CUNG_CAP,
  //   formThongTinChung: 'phieuNhapKhoAdd',
  //   formVatTu: 'themvtnhapkhorhm',
  //   pathApi: 'phieuNhapKhoAdd',
  //   pathApiPrint: PHIEU_IN_ENDPOINT.NHAP_KHO.NHA_CUNG_CAP,
  // },
];

export const INIT_GENERAL_VALUES = {
  receWarehouse: '',
  inlsendDate: '',
  supplier: '',
  doCreation: new Date(),
  mateWh: "",
  creator: dataUser?.sub,
  patientName: '',
  age: '',
  guardian: '',
  guardianPhoneNumber: '',
  patientGender: '',
  invoiceDate: '',
  suppName: '',
  content: '',
};
