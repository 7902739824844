import { FC } from "react";
import TableThongTinNhapThuoc from "../phan-he-nhap-kho/components/TableThongTinNhapThuoc";
import TableThongTinDieuChuyen from "../phan-he-dieu-chuyen/components/TableThongTinDieuChuyen";
import TableThongTinVTGoiThau from "../quan-ly-thau/components/TableThongTinVTGoiThau";
import TableVTTuTruc from "../phan-he-tu-truc/components/TableVTTuTruc";
import generateButton from "../component/select-thuoc-button/index";

interface DynamicProps {
  data?: any;
}

const DynamicComponent: FC<DynamicProps> = (props) => {
  const { data} = props;
console.log(data)
  const Default = () => {
    return <></>;
  };

  const components: { [key: string]: FC<any> } = {
    tableDSNhapKho: TableThongTinNhapThuoc,
    tableThongTinDieuChuyen: TableThongTinDieuChuyen,
    tableThongTinVTGoiThau: TableThongTinVTGoiThau,
    tableVatTuTuTruc: TableVTTuTruc,
    generateButton: generateButton,
  };

  const DynamicContent = components[data?.componentName] || Default;

  return <DynamicContent {...data} />;
};

export default DynamicComponent;