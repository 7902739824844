import { Button, Col, Modal, Row } from "react-bootstrap";
import { generateForm, generateHrTag } from "../../../utils/AppFunction";
import { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../../../appContext/AppContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import GenerateFormComponent from "../../../component/generate-form/GenerateFormHTMLNoValid";
import DynamicComponent from "../../../component/GenerateDynamicComponent";
import { CODE, DEFAULT_PAGE_INDEX, KEY, MAX_PAGE_SIZE, NAME_BUTTON_FLOW, SELECTION_MODE, TYPE_TU_TRUC, TYPE_WH, WF_TRACKER } from "../../../utils/Constant";
import { toast } from "react-toastify";
import { PhanHeTuTrucContext } from "../../PhanHeTuTruc";
import { formatDateDTO } from "../../../utils/FormatUtils";
import WfButton from "../../../workflows/components/WfButton";
import '../../PhanHeTuTruc.scss';
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TablePagination } from "../../../component/table/components/TablePagination";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../../utils/PageUtils";
import WfStatusSummary from "../../../workflows/components/WfStatusSummary";
import { summaryStatus, TU_TRUC } from "../../constants/TuTrucConstans";
import { NBCoSoTuTrucColumns, NBCoSoTuTrucColumnsView, TMCoSoTuTrucColumns } from "../../constants/TuTrucColumns";
import moment from "moment";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import { RESPONSE_CODE_SUCCESS } from "../../../category-personnel/const/PersonnelConst";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { WMS_STATUS } from "../../../phan-he-nhap-kho/constants/constansNhapKho";
import InputSearch from "../../../component/InputSearch";
import { createPhieuNhapBuCoSoTuTruc, getDsVatTuPhieu, getListMedicine, printPhieu, updatePhieuNhapBuCoSoTuTruc, updateSlPhat } from "./services";
import "./index.scss"
import ButtonIn from "../../../component/button-in-phieu";

interface Props {
  show: boolean;
  onHide: () => void;
  initialValues: any;
  modelId: any;
}

interface InventoryItem {
  iventory: number;
  code: string;
  unitofMeasure: string;
  materialGroup: number;
  materialType: string;
  name: string;
  peShipment: number;
  id: number;
  unitofMeasureName: string;
}

const DialogThemPhieuNhapBuCoSoTuTruc = (props: Props) => {
  const { show, onHide, initialValues, modelId } = props;
  const { setIsLoading } = useContext(AppContext);
  const {dsVatTu } = useContext(PhanHeTuTrucContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [lstItem, setLstItem] = useState<any>([])
  const [itemSelected, setItemSelected] = useState<any>([] as any)
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false)
  const [statusPhieu, setStatusPhieu] = useState<any>({ status: WMS_STATUS.warehouse_new });
  const formRef = useRef<any>()
  const [searchData, setSearchData] = useState<any>();
  const dataSlPhatRef = useRef({});
  let items: { itemId: number, serialNumber: string, consignment: string, totalQuantity: number }[] = []

  const generateValueByStatus: boolean = useMemo(() =>
    !modelId.title && [WMS_STATUS.warehouse_start].includes(initialValues[modelId.id]?.statusCode) ? true : false
    , [statusPhieu.status, modelId.title])

  const handlerUpdateSLPhat = async (elements: any) => {
    try {
      let countErr = 0;
      let eleError = Array.from(elements) as any;
      lstItem.forEach((item: any, index: number) => {
        if ((Number(eleError[index].innerText) > item?.requireQuantity) || eleError[index].innerText == "0") {
          countErr += 1
          return eleError[index].style.borderColor = "red"
        } {
          items = items.filter(val => val.itemId !== item?.item?.id)
          items.push({
            itemId: item?.item?.id,
            serialNumber: item?.serialNumber,
            consignment: item?.consignment,
            totalQuantity: Number(eleError[index].innerText) // số lượng phát
          })
          countErr -= 1
          return eleError[index].style.borderColor = '#ccc'
        }
      });
      !countErr && toast.warning("Số lượng phát là số nguyễn dương và không vượt số lượng yêu cầu!")
      let objSlPhat= {
        slipId: initialValues[modelId.id]?.id,
        type: initialValues[modelId.id]?.type,
        lstItem: items
      }
      dataSlPhatRef.current = objSlPhat;
    } catch (error) {
      console.error("ERR", error)
    } finally {

    }

  }

  useEffect(() => {
    getSelectedRowsData();
    updateForm();
  }, []);

  useEffect(() => {
    generateHrTag('#kt_app_body > div.fade.page-full.spaces.h-calc-vh-50.modal.show > div > div > form > div.spaces.p-0.bg-white.modal-body > div > form > div:nth-child(1)')
  }, [listJsonGenerate])

  useEffect(() => {
    const elements = document.querySelectorAll<HTMLDivElement>("div.onlyAcceptNumber");

    const handleKeydown = (event: any, index: number) => {
      // Kiểm tra nếu ký tự nhập vào không phải là số (0-9) và không phải là phím điều khiển
      const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
      if (!/[0-9]/.test(event.key)
        && !controlKeys.includes(event.key)) {
        return event.preventDefault();
      }
      setTimeout(() => {
        const elements = document.querySelectorAll<HTMLDivElement>("div.onlyAcceptNumber");
        handlerUpdateSLPhat(elements)
      }, 0)
    };

    Array.from(elements).forEach((ele, index) => {
      ele.addEventListener('keydown', (e) => handleKeydown(e, index));
    });

    return () => {
      Array.from(elements).forEach((ele, index) => {
        ele.removeEventListener('keydown', (e) => handleKeydown(e, index));
      });
    };
  }, [lstItem]);

  const validationSchema = Yup.object({
    [modelId.id]: Yup.object({
    })
  })

  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const updateForm = async () => {
    const data = await generateForm(modelId.id, setIsLoading);
    setListJsonGenerate(data || []);
  };

  const generateSlipDto = (values:any) =>
    ({
    ...values[modelId.id],
    code: values[modelId.id].code,
    idFake: new Date(),
    sellingPrice: Number(values[modelId.id].sellingPrice) || 0,
    totalQuantity: Number(values[modelId.id].totalQuantity) || 0,
    // totalAmount: caculatorTotalAmount(values[modelId.id]),
    vat: Number(values[modelId.id].vat) || 0,
    whReceiveName: values[modelId.id].whReceive.whReceiveName,
    whReceive: values[modelId.id].whReceive.whReceiveCode,
    whReceiveId: values[modelId.id].whReceive.id,
    type: TU_TRUC.NHAP_BU_CO_SO // Nhập bù cơ sô
  })

  const handleSubmit = async (values: any) => {
    if(!itemSelected.length) return toast.warning("Thông tin phiếu không có!")
    if(!values[modelId.id].whIssue) return toast.warning("Kho không được để trống!")
      try {
        setIsLoading(true)
        const data = {
          slipDto: generateSlipDto(values),
          lstItem: itemSelected.map((item: any) => {
            item.iventory = Number(item.iventory)
            return {
              ...item,
              id: item?.item?.id,
              requireQuantity: item?.item?.totalQuantity,
              itemId: item?.item?.item?.id,
              serialNumber: item?.item?.serialNumber,
              code: item?.item?.item?.code,
              name: item?.item?.item?.name,
              consignment: item?.item?.consignment,
              expiryDate: item?.item?.expiryDate
            }
          })
        };
        let res = initialValues[modelId.id]?.id ?
          await updatePhieuNhapBuCoSoTuTruc(initialValues[modelId.id]?.id, data) :
          await createPhieuNhapBuCoSoTuTruc(data);
        if(res?.data?.code === RESPONSE_CODE_SUCCESS) {
          toast.success("Thành công");
          onHide()
        }
      } catch (error) {
        console.error("ERR", error)
      } finally {
        setIsLoading(false)
      }
  };

  const getSelectedRowsData = async () => {
    try {
      setIsLoading(true);
      const { data } = initialValues[modelId.id].id ? await
        getDsVatTuPhieu({
          pageSize: MAX_PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX, slipId: initialValues[modelId.id]?.id, type: modelId.title ? 1 : 2 // 1 tủ trực 2 kho nhà thuốc
        }) : await getListMedicine({
          pageSize: MAX_PAGE_SIZE,
          pageIndex: DEFAULT_PAGE_INDEX,
          warehouseId: initialValues[modelId.id]?.whReceive?.id,
          type: 10
        })
      if (CODE.SUCCESS === data.code) {
        // setConfigTable({
        //   totalPages: data.data.pageable.totalElements / data.data.pageable.pageSize,
        //   totalElements: data.data.pageable.totalElements,
        //   numberOfElements: data.data.pageable.pageSize,
        // });
        let values = initialValues[modelId.id].id ? data.data.content : data.data;
        setLstItem(values.map((item: any) => ({ ...item, isEdit: generateValueByStatus }))) // thêm isEdit cho phép sửa sl phát bên kho nhà thuốc và không cho sửa bên tủ trực
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại');
      };
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, handleSubmit, setFieldValue, setValues }) => {
          return <Form ref={formRef} onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex gap-6">
                <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={onHide}
                >
                  <i className="spaces bi bi-chevron-left ml-10"></i>
                  <div className="spaces mr-20 my-3">Quay lại</div>
                </div>
                {
                  modelId.title &&
                  <div className="spaces mt-4 title">
                    {`${values[modelId.id]?.id ? 'Cập nhật' : 'Thêm mới'} ${modelId.title}`}
                  </div>
                }
              </div>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10 mb-10">
                {/* <h4 className="text-title fw-bold fs-5 spaces mb-5">Thông tin phiếu</h4> */}
                <div>
                  <GenerateFormComponent
                    warehouseId={values[modelId.id]?.whIssueId || initialValues[modelId.id]?.whIssueId}
                    listFieldAuto={listJsonGenerate}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    errors={errors}
                    modelID={modelId.id}
                    customComponent={DynamicComponent}
                    isViewSelectThuoc={!modelId.title || statusPhieu.status !== WMS_STATUS.warehouse_new}
                    isView={!modelId.title || statusPhieu.status !== WMS_STATUS.warehouse_new || values[modelId.id]?.id}
                  />
                </div>
                {!modelId.title &&
                  <Row className="mt-5">
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <div className="">
                        <label className="form-label fw-bold">Tạo phiếu:&nbsp;</label>
                        <span className="form-value">{initialValues[modelId.id].createSlip}</span>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <div className="">
                        <label className="form-label fw-bold">Duyệt phiếu:&nbsp;</label>
                        <span className="form-value">{initialValues[modelId.id].approveSlip}</span>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <div className="">
                        <label className="form-label fw-bold">Xuất phiếu:&nbsp;</label>
                        <span className="form-value">{initialValues[modelId.id].exportSlip}</span>
                      </div>
                    </Col>
                  </Row>
                }
                {modelId.title &&
                  <div className="d-flex spaces pr-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                    <div className="flex-grow-1 ">
                      <InputSearch
                        name="keyword"
                        value={searchData?.keyword || ''}
                        placeholder="Tìm kiếm"
                        handleChange={handleChangeSearchData}
                        handleSearch={() => { }}
                        // handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && getSelectedRowsData()}
                        className="spaces h-31 pr-4  radius-3"
                      />
                    </div>
                  </div>
                }
              </div>
              <div className=" spaces bg-white mb-0">
                <TableCustom<any>
                  key={statusPhieu?.status}
                  className={`spaces ${modelId.title ? "h-70vh" : "h-72vh"}`}
                  data={lstItem}
                  columns={initialValues[modelId.id].id ? NBCoSoTuTrucColumnsView : NBCoSoTuTrucColumns}
                  verticalScroll={true}
                  selectionMode={(!modelId?.title || initialValues[modelId.id].id) ? SELECTION_MODE.SINGLE_NO_RADIO_BUTTON : SELECTION_MODE.MULTI}
                  getSelectedRowsData={(item:any) => setItemSelected(item)}
                />
                {modelId.title &&
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={configTable.totalPages}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    hideTotalElements
                    // customComponent={<WfStatusSummary hiddenQuantity summaryStatus={summaryStatus} col={3} />}
                    customComponentClassName="min-w-600px"
                    className="border border-0"
                  />
                }
              </div>
            </Modal.Body>
            <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6">
              <WfButton
                type={modelId.title ? 1 : 2} // 1 tủ trực 2 kho nhà thuốc
                tracker={WF_TRACKER.NHAP_BU_CO_SO_TU_TRUC} // button
                objectId={values[modelId.id]?.id}
                additionalFunc={(data) => {
                  setStatusPhieu({
                    status: data?.currCode
                  })
                }}
              />
              {(WMS_STATUS.warehouse_new === statusPhieu?.status) && modelId.title && !initialValues[modelId.id].id &&
                <Button type="submit" className="btn btn-fill min-w-90px spaces ms-5">
                  Lưu
                </Button>
              }
              {(![WMS_STATUS.warehouse_new].includes(initialValues[modelId.id]?.statusCode)) &&
                <ButtonIn
                  className="spaces min-w-120 mx-3"
                  fetchExport={printPhieu}
                  params={{
                    slipId: initialValues[modelId.id]?.id,
                    type: modelId.title ? 1 : 2 // 1 tủ trực 2 kho xuất
                  }}
                />
              }
              {(!modelId.title && [WMS_STATUS.warehouse_start].includes(initialValues[modelId.id]?.statusCode) && [WMS_STATUS.warehouse_start].includes(statusPhieu.status) ) &&
                <Button onClick={() => updateSlPhat(dataSlPhatRef.current, getSelectedRowsData)} className="btn btn-fill min-w-90px spaces ms-1">
                  Lưu phiếu
                </Button>
              }
            </div>
          </Form>
        }}
      </Formik>
      {openDialogConfirmDelete &&
          <ConfirmDialog
            show={openDialogConfirmDelete}
            onCloseClick={() => setOpenDialogConfirmDelete(false)}
            onYesClick={() => {}}
            title="Xóa phiếu"
            message={`Bạn chắc chắn muốn xóa ?`}
            yes="Xóa"
            close="Hủy"
          />
        }
    </Modal>
  );
};

export default DialogThemPhieuNhapBuCoSoTuTruc;
