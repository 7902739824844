import { OctTable } from "@oceantech/oceantech-ui";
import { Dispatch, SetStateAction, useContext } from "react";
import { formatMoney } from "../../utils/FormatUtils";
import { DuTruBoSungVTKhoContext } from "../DuTruBoSungVTKho";
import '../DuTruBoSungVTKho.scss';
interface Props {
  setIsChangeRowData: Dispatch<SetStateAction<boolean>>,
  itemSelected?: any,
}

const TableThongTinVT = (props: Props) => {
  const { setIsChangeRowData, itemSelected } = props;
  const { dsNhapKho, setDsNhapKho } = useContext(DuTruBoSungVTKhoContext);

  const handleInputChange = (index: number, value: any, name: string) => {
    const newLstItem = [...dsNhapKho];
    newLstItem[index] = {
      ...newLstItem[index],
      [name]: value,
    };
    setDsNhapKho(newLstItem);
  };

  const columns = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => stt,
    },
    {
      name: "Mã vật tư",
      field: "code",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.code || row.item?.code || "",
    },
    {
      name: "Tên vật tư",
      field: "name",
      headerStyle: {
        minWidth: 200,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.name || row.item?.name || "",
    },
    {
      name: "ĐVT",
      field: "unitofMeasureName",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) =>
        row.unitofMeasureName || row.item?.unitofMeasureName || "",
    },
    {
      name: "SL yêu cầu",
      field: "requireQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        formatMoney(row?.requireQuantity) || "",
    },
    {
      name: "SL duyệt",
      field: "totalQuantity",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        itemSelected?.statusCode === "warehouse_start" ? (
          <input
            className="min-w-80 w-100 border-custom-input text-end"
            type="number"
            defaultValue={row.totalQuantity || ""}
            onInput={(e: any) => setIsChangeRowData(true)}
            onBlur={(e: any) =>
              handleInputChange(index, e.target.value, "totalQuantity")
            }
          />
        ) : (
          formatMoney(row.totalQuantity)
        ),
    },
    {
      name: "Đơn giá",
      field: "sellingPrice",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        formatMoney(row?.sellingPrice) || "",
    },
    {
      name: "Thành tiền",
      field: "vat",
      headerStyle: {
        minWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        formatMoney(row.totalQuantity * row.sellingPrice),
    },
    {
      name: "Số QĐ",
      field: "",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      name: "SL duyệt khả dụng",
      field: "item.iventory",
      headerStyle: {
        minWidth: 140,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.item?.iventory) || '',
    },
    {
      name: "SL chưa duyệt",
      field: "",
      headerStyle: {
        minWidth: 120,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) =>
        formatMoney(
          parseFloat(row?.requireQuantity) - parseFloat(row?.totalQuantity)
        ) || "",
    },
    {
      name: "Tồn thầu",
      field: "",
      headerStyle: {
        minWidth: 110,
      },
      cellStyle: {
        paddingRight: "10px",
        textAlign: "right",
      },
    },
  ];

  return (
    <div className="spaces mt-12 border-table custom-table">
      <h4 className="spaces mt-8 ml-10 title">Danh sách vật tư</h4>
      <OctTable
        id="table-lst-item-du-tru-bo-sung-vt"
        className="spaces custom-header-table"
        columns={columns}
        data={dsNhapKho}
        noToolbar
        clearToolbar
        noPagination
        fixedColumnsCount={-1}
      />
    </div>
  );
};

export default TableThongTinVT;
