import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../appContext/AppContext";
import { CODE, DEFAULT_PAGE_INDEX, KEY, SELECTION_MODE, TYPE_WH, WF_TRACKER } from "../utils/Constant";
import TextField from "../component/TextField";
import InputSearch from "../component/InputSearch";
import { TableCustom } from "../component/table/table-custom/TableCustom";
import CustomIconButton from "../component/custom-icon-button/CustomIconButton";
import { TableCustomHeader } from "../component/table/components/TableCustomHeader";
import { TablePagination } from "../component/table/components/TablePagination";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../utils/PageUtils";
import WfStatusSummary from "../workflows/components/WfStatusSummary";
import { ConfirmDialog } from "../component/ConfirmDialog";
import { deletePhieuTongHop, searchPhieuTongHop } from "./services";
import { toast } from "react-toastify";
import DialogThemPhieuTongHop from "./DialogThemPhieuTongHop";
import { localStorageItem } from "../utils/LocalStorage";
import { getSummaryStatusByIdWarehouse, getSummaryStatusByType } from "../workflows/services/WfWorkflowsService";
import { WMS_STATUS } from "../phan-he-nhap-kho/constants/constansNhapKho";
import { DanhSachPhieuColumns } from "./constants/Columns";
import { formatDateFromDTOCustom, formatDateToDTOCustom } from "../utils/FormatUtils";


type Props = {
  modelId: any;
  warehouseId?: any;
}

export const DuTruBoSung: FC<Props> = (props) => {
  const { modelId, warehouseId } = props;
  const { setIsLoading } = useContext(AppContext);
  const [dsPhieuTongHop, setDsPhieuTongHop] = useState([]);
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<any>({});
  const [searchData, setSearchData] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [summaryStatus, setSummaryStatus] = useState<any>([]);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const dataUser = localStorageItem.get("current-user");




  useEffect(() => {
    updatePageData();
  }, [page, rowsPerPage, modelId.type]);




  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);

      const resSummary = await getSummaryStatusByType(
        WF_TRACKER.TONG_HOP_BO_SUNG,
        TYPE_WH.TONG_HOP_DU_TRU,
        {
          whIssueId: warehouseId,
          fromDate: formatDateFromDTOCustom(searchData?.doCreationFromDate) || null,
          toDate: formatDateToDTOCustom(searchData?.doCreationToDate) || null,
        }
      );

      if (CODE.SUCCESS === resSummary.data?.code) {
        setSummaryStatus(resSummary.data.data);
      }

      const { data } = await searchPhieuTongHop({
        ...searchData,
        pageIndex: page,
        pageSize: rowsPerPage,
        type: modelId?.type,
        warehouseId: warehouseId
      });

      if (CODE.SUCCESS === data.code) {
        setDsPhieuTongHop(data?.data?.content);
        setConfigTable({
          totalPages: data.data.totalPages,
          totalElements: data.data.totalElements,
          numberOfElements: data.data.numberOfElements,
        });
      } else {
        toast.error('Lỗi tìm kiếm phiếu, vui lòng thử lại');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDataSumary = async (idWarehouse: number) => {
    if (modelId.title && !idWarehouse) return; // k goi o tu truc khi k co id
    const resSummary = await getSummaryStatusByIdWarehouse(WF_TRACKER.DU_TRU_BO_SUNG, modelId.type, idWarehouse);
    if (CODE.SUCCESS === resSummary?.data?.code) {
      setSummaryStatus(resSummary.data.data);
    };
  }

  const handleCloseDialog = () => {
    setOpenDialogThemMoi(false);
    setOpenDialogConfirmDelete(false);
    setItemSelected({});
    updatePageData();
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deletePhieuTongHop(itemSelected.id);
      if (CODE.SUCCESS === response.status) {
        toast.success(`Xóa ${modelId.title} thành công`);
        handleCloseDialog();
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center gap-3">
        <>
          <CustomIconButton
            variant="edit"
            onClick={() => {
              setItemSelected(props.cell.row.original);
              setOpenDialogThemMoi(true);
            }}
          >
            <i className="bi bi-pencil-square text-navy"></i>
          </CustomIconButton>
          {props.cell.row.original?.statusCode === WMS_STATUS.warehouse_new &&
            <CustomIconButton
              variant="delete"
              onClick={() => {
                setItemSelected(props.cell.row.original);
                setOpenDialogConfirmDelete(true);
              }}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          }
        </>
      </div>
    )
  };

  return (
    <div className="bg-white h-100">
      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-100 bg-gray radius-2">
            <div className="flex justify-content-between bg-white spaces w-100 pt-6">
              <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10 custom-title">
                {`Danh sách tổng hợp`}
              </h4>
            </div>
            <div className="d-flex mt-1 flex-column spaces width-100">
              <div className="bg-white">
                <div className="d-flex spaces pr-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  <div className="d-flex spaces mx-10 ">
                    <TextField
                      label="Từ ngày"
                      name="startDate"
                      type="date"
                      value={searchData?.startDate || ''}
                      className="flex"
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="d-flex spaces mr-10">
                    <TextField
                      label="Đến ngày"
                      name="endDate"
                      type="date"
                      className="flex"
                      value={searchData?.endDate || ''}
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="flex-grow-1 ">
                    <InputSearch
                      name="keyword"
                      value={searchData?.keyword || ''}
                      placeholder="Tìm kiếm"
                      handleChange={handleChangeSearchData}
                      handleSearch={updatePageData}
                      handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && updatePageData()}
                      className="spaces h-31 pr-4  radius-3"
                    />
                  </div>
                </div>
                <div className=" spaces bg-white mb-0">
                  <TableCustom<any>
                    className="spaces h-calc-vh-210 table-ds-tong-hop-du-tru"
                    data={dsPhieuTongHop}
                    columns={DanhSachPhieuColumns}
                    columnsActions={columnsActions}
                    verticalScroll={true}
                    selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={configTable.totalPages}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    hideTotalElements
                    customComponent={<WfStatusSummary summaryStatus={summaryStatus} col={2} />}
                    customComponentClassName="min-w-900px"
                    className="border border-0"
                  />
                </div>
              </div>
            </div>
          </div>
          {openDialogThemMoi &&
            <DialogThemPhieuTongHop
              show={openDialogThemMoi}
              onHide={handleCloseDialog}
              initialValues={{
                [modelId.id]: {
                  ...itemSelected,
                  whReceive: itemSelected?.whReceive,
                  createdDate: itemSelected?.createdDate || new Date(),
                  notes: itemSelected?.notes,
                  createdBy: dataUser?.sub,
                  code: null
                }
              }}
              warehouseId={warehouseId}
              modelId={modelId.id}
            />
          }
          {openDialogConfirmDelete &&
            <ConfirmDialog
              show={openDialogConfirmDelete}
              onCloseClick={handleCloseDialog}
              onYesClick={handleDelete}
              title="Xóa phiếu"
              message={`Bạn chắc chắn muốn xóa phiếu: ${itemSelected.code} ?`}
              yes="Xóa"
              close="Hủy"
            />
          }
        </div>
      </div>
    </div>
  );
};

export default DuTruBoSung;
