import { FC, useContext, useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import { generateForm, generateInitValues, generateValidationSchema, roundToDecimalPlaces } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import GenerateFormComponent from "../../../../component/generate-form/GenerateFormHTMLNoValid";
import { PhanHeXuatKhoContext } from "../../../PhanHeXuatKho";
import { toast } from "react-toastify";
import { WMS_STATUS } from "../../../constants/constantsXuatKho";
import { EXPORT_CODE } from "../../../constants/constantsXuatKho";
import DynamicComponent from "../../../../component/GenerateDynamicComponent";
import {WAREHOUSE_ID} from "../../../../utils/Constant";

type Props = {
  generalValues: any,
  statusPhieu: any,
  inventoryId: string | undefined,
  id: string | undefined,
  warehouseExportType?: any,
  warehouseId: number,
  isView?: boolean;
};

type VatTuProps = {
  generalValues: any,
  warehouseId?: any,
  id?: string | undefined,
  listJsonGenerate: string,
  vatTuSelected: any,
  initialValues?: any;
  statusPhieu: any;
  randomValue: any;
  setInitialValues?: any;
  warehouseExportType?: any;
  isView?: boolean;
};


const FormVatTu = (props: VatTuProps) => {
  const {
    id,
    generalValues,
    listJsonGenerate,
    warehouseId,
    setInitialValues,
    initialValues,
    warehouseExportType,
    statusPhieu,
    vatTuSelected,
    randomValue,
    isView,
  } = props;
  const {
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormikContext<any>();

  const { thongTinPhieu } = useContext(PhanHeXuatKhoContext);
  const renderButton = () => {
    const txtUpdateMedicine = warehouseId === WAREHOUSE_ID.KHO_MAU ? 'Cập nhật Máu/CP' : 'Cập nhật Thuốc/VT';
    const txtAddMedicine = warehouseId === WAREHOUSE_ID.KHO_MAU ? 'Thêm Máu/CP' : 'Thêm Thuốc/VT';

    if (EXPORT_CODE.KHACH_HANG == warehouseExportType?.code) {
      return <>
        {!id && (
          <Button
            className="btn btn-fill custom-position-btn spaces min-w-100"
            onClick={(e) => handleSubmit(values)}
          >
            {(vatTuSelected.id || vatTuSelected?.index >= 0) ? txtUpdateMedicine : txtAddMedicine}
          </Button>
        )}
      </>
    } else if (EXPORT_CODE.NCC === warehouseExportType?.code) {
      return <>
        {WMS_STATUS.warehouse_approved !== statusPhieu?.status && (
          <Button
            className="btn btn-fill custom-position-btn spaces min-w-100"
            onClick={(e) => handleSubmit(values)}
          >
            {(vatTuSelected.id || vatTuSelected?.index >= 0) ? txtUpdateMedicine : txtAddMedicine}
          </Button>
        )}
      </>
    } else if (EXPORT_CODE.KHAC === warehouseExportType?.code) {
      return <>
        {WMS_STATUS.warehouse_approved !== statusPhieu?.status && (
          <Button
            className="btn btn-fill custom-position-btn spaces min-w-100"
            onClick={(e) => handleSubmit(values)}
          >
            {(vatTuSelected.id || vatTuSelected?.index >= 0) ? txtUpdateMedicine : txtAddMedicine}
          </Button>
        )}
      </>
    } else if (EXPORT_CODE.HUY === warehouseExportType?.code) {
      return <>
        {WMS_STATUS.warehouse_approved !== statusPhieu?.status && (
          <Button
            className="btn btn-fill custom-position-btn spaces min-w-100"
            onClick={(e) => handleSubmit(values)}
          >
            {(vatTuSelected.id || vatTuSelected?.index >= 0) ? txtUpdateMedicine : txtAddMedicine}
          </Button>
        )}
      </>
    } else if (EXPORT_CODE.HAO_PHI === warehouseExportType?.code) {
      return <>
        {WMS_STATUS.warehouse_approved !== statusPhieu?.status && (
          <Button
            className="btn btn-fill custom-position-btn spaces min-w-100"
            onClick={(e) => handleSubmit(values)}
          >
            {(vatTuSelected.id || vatTuSelected?.index >= 0) ? txtUpdateMedicine : txtAddMedicine}
          </Button>
        )}
      </>
    }
  }

  useEffect(() => {
    if (values && values.formThemMoiVatTuXuatKho.item) {
      const data = values.formThemMoiVatTuXuatKho.item || {};
      setFieldValue('formThemMoiVatTuXuatKho', {
        // item: data.code,
        itemId: data.id,
        itemName: data.name,
        code: data.code,
        iventory: data.iventory || '',
        availableQuantity: data.availableQuantity || '',
        sellingPrice: data.sellingPrice || '',
        importPrice: data.importPrice || '',
        packaging: data.packaging || '',
        unitId: data.unitofMeasureName,
        unitofMeasureName: data.unitofMeasureName,
        concentration: data.concentration,
        dosage: data.dosage,
        countryofOrigin: data.countryofOrigin,
        manufacturer: data.manufacturer,
        totalQuantity: values.formThemMoiVatTuXuatKho?.totalQuantity,
        vat: data.vat,
        registrationNumber: data.registrationNumber,
        consignment: data.consignment || '',
        expiryDate: data.expiryDate,
        usageIns: data.usageIns || '',
        hospitalPrice: data.hospitalPrice || '', 
        salePrice: data.salePrice || '', 
        item: values.formThemMoiVatTuXuatKho.item
      })
    }
  }, [values?.formThemMoiVatTuXuatKho?.itemId]);

  useEffect(() => {
    const data = values.formThemMoiVatTuXuatKho;
    const quantity = parseFloat(values.formThemMoiVatTuXuatKho?.totalQuantity) || 0;
    let price = 0;
    if (EXPORT_CODE.KHACH_HANG == warehouseExportType?.code) {
      price = data?.salePrice || 0
    } else {
      price = data?.sellingPrice || 0
    }
    const vat = data.vat || 0;

    setFieldValue('formThemMoiVatTuXuatKho', {
      ...values.formThemMoiVatTuXuatKho,
      totalAmount: roundToDecimalPlaces(quantity * price * (1 + vat / 100)),
    })
  }, [values.formThemMoiVatTuXuatKho?.totalQuantity, values.formThemMoiVatTuXuatKho?.importPrice, values.formThemMoiVatTuXuatKho?.sellingPrice, values.formThemMoiVatTuXuatKho?.vat]);

  useEffect(() => {
    if (randomValue) {
      setFieldValue('formThemMoiVatTuXuatKho', {});
    };
  }, [randomValue]);
  const handler = (values: any) => {
    setInitialValues({
      formThemMoiVatTuXuatKho: { ...initialValues?.formThemMoiVatTuXuatKho, ...vatTuSelected, ...values },
    })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <GenerateFormComponent
        isView={!generalValues.phieuXuatKhoAdd?.mateWh || isView}
        warehouseId={warehouseId}
        values={values}
        listFieldAuto={listJsonGenerate}
        setFieldValue={setFieldValue}
        isIventoryNoZero={true}
        touched={touched}
        propsRenderButton={handler}
        lstSelectedRenderButton={thongTinPhieu.dsThuoc || []}
        errors={errors}
        DynamicClass={"pl-36"}
        modelID='formThemMoiVatTuXuatKho'
        customComponent={DynamicComponent}
      />
      {!!generalValues.phieuXuatKhoAdd?.mateWh && renderButton()}
    </Form>
  )
};

const FormThemVatTu: FC<Props> = (props) => {
  const { generalValues, inventoryId, statusPhieu, warehouseExportType, id, warehouseId, isView } = props;
  const { setIsLoading } = useContext(AppContext);
  const { thongTinPhieu, setThongTinPhieu, vatTuSelected, setVatTuSelected, randomValue } = useContext(PhanHeXuatKhoContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [initialValues, setInitialValues] = useState({
    formThemMoiVatTuXuatKho: {},
  });

  useEffect(() => {
    setInitialValues({
      formThemMoiVatTuXuatKho: { ...initialValues?.formThemMoiVatTuXuatKho, ...vatTuSelected?.item, ...vatTuSelected, },
    })
  }, [vatTuSelected]);


  const handlerAddItem = (values: any) => {
    const data = values.formThemMoiVatTuXuatKho || {};

    if (!data.code) {
      toast.warning('Chưa chọn vật tư');
      return;
    };

    if (
        !data.totalQuantity
        || isNaN(Number(data.totalQuantity))
        || data.totalQuantity <= 0
        || data.totalQuantity > data.availableQuantit
    ) {
      const message =
        !data.totalQuantity ? 'Chưa nhập số lượng'
        : isNaN(Number(data.totalQuantity)) ? "Định dạng số lượng không hợp lệ"
        : data.totalQuantity <= 0 ? 'Số lượng phải lớn hơn 0'
        : `Số lượng khả dụng không đủ (khả dụng: ${data.availableQuantity || 0})`
      toast.warning(message);
      return;
    };

    setInitialValues({
      formThemMoiVatTuXuatKho: {},
    });
    setVatTuSelected({});
    const dataSubmit = {
      inventoryId: inventoryId,
      itemId: data?.item?.id || data.itemId,
      item: data?.item || data,
      consignment: data?.consignment || data?.item?.consignment,
      sellingPrice: data?.sellingPrice || data?.item?.sellingPrice,
      hospitalPrice: data?.hospitalPrice || data?.item?.hospitalPrice,
      salePrice: data?.salePrice || data?.item?.salePrice,
      serialNumber: data?.serialNumber || data?.item?.serialNumber,
      expiryDate: data?.expiryDate || data?.item?.expiryDate,
      totalQuantity: Number(data.totalQuantity),
      warehouseId: warehouseId,
      id: vatTuSelected.id,
    };
    if (vatTuSelected?.id || vatTuSelected?.index?.toString()) {

      let lstThuoc = [...thongTinPhieu?.dsThuoc];
      let dataPhieu = { ...thongTinPhieu }
      lstThuoc[vatTuSelected?.index] = dataSubmit
      setThongTinPhieu({ ...dataPhieu, dsThuoc: lstThuoc });
    } else {
      let dsThuoc = [...thongTinPhieu?.dsThuoc || [], { ...dataSubmit }]

      setThongTinPhieu({ ...thongTinPhieu, dsThuoc: dsThuoc });
    }
    setInitialValues({ formThemMoiVatTuXuatKho: { ...generateInitValues(listJsonGenerate) } });
  };
  useEffect(() => {
    if (warehouseExportType.modelIdVT) getListform();
  }, [warehouseExportType.modelIdVT]);

  const getListform = async () => {
    let data = await generateForm(warehouseExportType?.modelIdVT || "formThemMoiVatTuXuatKho", setIsLoading);
    setListJsonGenerate(data);
    setInitialValues(generateInitValues(data, 'formThemMoiVatTuXuatKho'))
  };
  return (
    <div className="bg-white spaces px-10 pb-6 form-them-vat-tu-xk">
      <Formik<any>
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={validationSchema}
        // initialValues={generateInitValues(listJsonGenerate, 'formThemMoiVatTuXuatKho')}
        validationSchema={generateValidationSchema(listJsonGenerate, 'formThemMoiVatTuXuatKho')}
        onSubmit={handlerAddItem}
      >
        <FormVatTu
          id={id}
          isView={isView}
          generalValues={generalValues}
          warehouseId={warehouseId}
          warehouseExportType={warehouseExportType}
          listJsonGenerate={listJsonGenerate}
          // onSubmit={onSubmit}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          statusPhieu={statusPhieu}
          vatTuSelected={vatTuSelected}
          randomValue={randomValue}
        />
      </Formik>
    </div>
  );
};

export default FormThemVatTu;
